<template>
  <p class="text-gray-500 text-sm italic mb-5">
    <span class="text-red-500">*</span> -
    {{ lang === "mn" ? "Заавал бөглөх" : "Must be filled" }}
  </p>

  <div class="flex gap-10 mb-5">
    <div class="w-full">
      <label for="mainCategory"
        >{{ lang === "mn" ? "Үндсэн ангилал:" : "Main category:"
        }}<span class="text-red-500">*</span></label
      >
      <select
        name="mainCategory"
        id="mainCategory"
        class="w-full border p-2"
        v-model="form.mainCategory"
      >
        <option disabled value="">
          {{ lang === "mn" ? "Үндсэн ангилал" : "All categories" }}
        </option>
        <option value="01">
          {{ lang === "mn" ? "Уул уурхайн компани" : "Mining Companies" }}
        </option>
        <option value="02">
          {{ lang === "mn" ? "Ханган нийлүүлэгч" : "Supplier Companies" }}
        </option>
        <option value="03">
          {{ lang === "mn" ? "Үйлчилгээ үзүүлэгч" : "Service Companies" }}
        </option>
        <option value="04">
          {{ lang === "mn" ? "Бусад" : "Other Companies" }}
        </option>
      </select>
    </div>

    <div class="w-full">
      <label for="mainCategory"
        >{{ lang === "mn" ? "Дэд ангилал:" : "Sub category:"
        }}<span class="text-red-500">*</span></label
      >
      <select
        name="subCategory"
        id="subCategory"
        class="w-full border p-2"
        v-model="form.subCategory"
      >
        <option disabled value="">
          {{ lang === "mn" ? "Дэд ангилал" : "All sub-categories" }}
        </option>
        <option v-for="sub in subCategories" :key="sub.id" :value="sub.id">
          {{ sub.name }}
        </option>
      </select>
    </div>
  </div>

  <div class="flex gap-10 w-full">
    <div class="w-full">
      <label for="nameMn"
        >{{
          lang === "mn"
            ? "Байгууллагын нэр /монголоор/:"
            : "Company Name by Mongolian:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameMn"
        name="nameMn"
        type="text"
        placeholder="Байгууллагын нэр"
        class="w-full p-2 border"
        v-model="form.nameMn"
      />
    </div>

    <div class="w-full">
      <label for="nameEn"
        >{{
          lang === "mn"
            ? "Байгууллагын нэр /англиар/:"
            : "Company Name by English:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameEn"
        name="nameEn"
        type="text"
        placeholder="Company name"
        class="w-full p-2 border"
        v-model="form.nameEn"
      />
    </div>
  </div>

  <div class="flex justify-between gap-10 my-5">
    <div class="w-full">
      <label for="email">{{
        lang === "mn" ? "И-мэйл хаяг:" : "Email address:"
      }}</label>
      <input
        id="email"
        name="email"
        type="text"
        :placeholder="lang === 'mn' ? 'И-мэйл' : 'E-mail'"
        class="w-full p-2 border"
        v-model="form.email"
      />
    </div>

    <div class="w-full">
      <label for="phone">{{
        lang === "mn" ? "Утасны дугаар:" : "Phone number:"
      }}</label>
      <input
        id="phone"
        name="phone"
        type="text"
        :placeholder="lang === 'mn' ? 'Утас' : '#'"
        class="w-full p-2 border"
        v-model="form.phone"
      />
    </div>

    <div class="w-full">
      <label for="fax">{{ lang === "mn" ? "Факс:" : "Fax:" }}</label>
      <input
        id="fax"
        name="fax"
        type="text"
        :placeholder="lang === 'mn' ? 'Факс' : 'Fax'"
        class="w-full p-2 border"
        v-model="form.fax"
      />
    </div>
  </div>

  <label for="addressMn" class="mt-5">{{
    lang === "mn" ? "Хаяг /монголоор/:" : "Address by mongolian:"
  }}</label>
  <input
    id="addressMn"
    name="addressMn"
    type="text"
    placeholder="Хаяг"
    class="w-full p-2 border mb-5"
    v-model="form.addressMn"
  />

  <label for="addressEn">{{
    lang === "mn" ? "Хаяг /англиар/:" : "Address by english:"
  }}</label>
  <input
    id="addressEn"
    name="addressEn"
    type="text"
    placeholder="Address"
    class="w-full p-2 border mb-5"
    v-model="form.addressEn"
  />

  <div class="flex mb-5">
    <h4 class="w-[15rem]">Лого:<span class="text-red-500">*</span></h4>
    <div class="space-y-3 w-full">
      <div
        class="flex flex-col justify-center items-center"
        :class="mainImage ? 'w-1/3 object-contain' : 'h-40 w-40'"
      >
        <img
          :src="imageSrc"
          alt="image"
          :class="!mainImage ? 'w-14 h-14' : ''"
        />
        <span v-if="!mainImage" class="text-xs text-gray-500"
          >Зураг байхгүй байна</span
        >
      </div>
      <input
        type="file"
        class="w-full bg-white border p-2"
        @change="onImageChange"
        accept="image/jpeg, image/png"
      />
    </div>
  </div>

  <label for="aboutMn" class="mt-5">{{
    lang === "mn"
      ? "Товч танилцуулга /монголоор/:"
      : "Introduction by mongolian:"
  }}</label>
  <textarea
    id="aboutMn"
    name="aboutMn"
    type="text"
    rows="6"
    placeholder="Танилцуулга"
    class="w-full p-2 border mb-5"
    v-model="form.aboutMn"
  >
  </textarea>

  <label for="aboutEn" class="mt-5">{{
    lang === "mn" ? "Товч танилцуулга /англиар/:" : "Introduction by english:"
  }}</label>
  <textarea
    id="aboutEn"
    name="aboutEn"
    type="text"
    rows="6"
    placeholder="Introduction"
    class="w-full p-2 border mb-5"
    v-model="form.aboutEn"
  >
  </textarea>

  <div class="flex justify-center">
    <button
      @click="submitRegistration"
      class="bg-[#CC4628] uppercase text-white p-2 rounded-xl group"
    >
      <font-awesome-icon
        icon="fa-solid fa-paper-plane"
        class="mr-1 transition-transform duration-300 group-hover:rotate-45"
      />
      {{ lang === "mn" ? "Илгээх" : "Submit" }}
    </button>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowCompanyRegistration",
  components: {
    SuccessModal,
    ErrorModal,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const subCategories = ref([]);
    const success = ref(null);
    const error = ref(null);
    const form = ref({
      mainCategory: "01",
      subCategory: "",
      nameMn: "",
      nameEn: "",
      email: "",
      phone: "",
      fax: "",
      addressMn: "",
      addressEn: "",
      aboutMn: "",
      aboutEn: "",
      approval: false,
    });

    // Main image
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    const fetchSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/categories?main=${form.value.mainCategory}&language=${lang.value}`
        );
        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchSubCategories();
    });

    // Form reset
    const resetForm = () => {
      imageSrc.value = defaultImage;
      mainImage.value = "";
      form.value = {
        mainCategory: "01",
        subCategory: "",
        nameMn: "",
        nameEn: "",
        email: "",
        phone: "",
        fax: "",
        addressMn: "",
        addressEn: "",
        aboutMn: "",
        aboutEn: "",
        approval: false,
      };
    };

    // VALIDATION
    const isValid = () => {
      if (!form.value.mainCategory) {
        return alert("Үндсэн ангиллыг сонгоно уу");
      }
      if (!form.value.subCategory) {
        return alert("Дэд ангиллыг сонгоно уу");
      }
      if (!mainImage.value) {
        return alert("Лого оруулна уу");
      }
      if (!form.value.nameMn)
        return alert("Байгууллагын нэрийг /монголоор/ оруулна уу");
      if (!form.value.nameEn)
        return alert("Байгууллагын нэрийг /англиар/ оруулна уу");

      return true;
    };

    // SUBMIT
    const submitRegistration = async () => {
      if (!isValid()) return;

      const formData = new FormData();
      formData.append("subCategory", form.value.subCategory);
      formData.append("image", mainImage.value);
      formData.append("nameMn", form.value.nameMn);
      formData.append("nameEn", form.value.nameEn);
      formData.append("email", form.value.email);
      formData.append("phone", form.value.phone);
      formData.append("fax", form.value.fax);
      formData.append("addressMn", form.value.addressMn);
      formData.append("addressEn", form.value.addressEn);
      formData.append("aboutMn", form.value.aboutMn);
      formData.append("aboutEn", form.value.aboutEn);
      formData.append("approval", form.value.approval);

      try {
        const res = await clientYellowInstance.post(
          "/insert/yellow-registration",
          formData
        );
        if (res.status === 200) {
          success.value = "Хүсэлт амжилттай илгээгдлээ.";
          resetForm();
          setTimeout(() => {
            success.value = null;
          }, 5000);
        } else {
          error.value = "Алдаа гарлаа.";
          setTimeout(() => {
            error.value = null;
          }, 5000);
        }
      } catch (err) {
        error.value = "Алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 5000);
      }
    };

    return {
      lang,
      form,
      onImageChange,
      imageSrc,
      mainImage,
      submitRegistration,
      success,
      error,
      subCategories,
    };
  },
};
</script>
