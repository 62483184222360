<template>
  <main class="flex justify-center font-zona">
    <div class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-5 md:py-10">
      <img
        src="https://miningmongolia.mn/static/1723448317787.png"
        alt="mining 101"
        class="w-56 object-contain"
      />

      <div class="grid grid-cols-1 sm:grid-cols-2 gap-10 mt-5">
        <div v-for="video in videos" :key="video.id" class="w-full">
          <h1
            class="bg-gradient-to-r from-gray-100 to-white text-green font-bold tracking-wide uppercase px-3 py-1 border-l-4 border-green"
          >
            {{ video.title }}
          </h1>
          <video class="w-full mt-5" controls>
            <source :src="video.video" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MiningHundred",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const videos = ref([]);
    const isLoading = ref(false);

    const fetchVideos = async () => {
      try {
        isLoading.value = true;
        const videoRes = await clientInstance.get("/get/mining-video");
        if (videoRes.status === 200) {
          videos.value = videoRes.data.video;
        }
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchVideos();
    });

    return { lang, videos, isLoading };
  },
};
</script>
