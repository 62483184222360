<template>
  <main class="flex justify-center font-zona">
    <div
      class="flex max-sm:flex-col gap-5 lg:gap-10 w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-5"
    >
      <!-- NEWS LIST -->
      <div v-if="news.length > 0 && !isLoading" class="w-full">
        <div
          class="grid grid-cols-1 min-[900px]:grid-cols-2 gap-10 w-full h-fit"
        >
          <router-link
            v-for="item in news"
            :key="item.id"
            :to="`/mm/detail/${item.id}`"
            v-motion-slide-visible-once-bottom
            :duration="1000"
            class="bg-gray-100 p-5 space-y-5 shadow cursor-pointer"
          >
            <h6 class="font-bold text-sm text-green">{{ item.created_at }}</h6>
            <img
              :src="item.image"
              :alt="item.id"
              class="w-full h-[12rem] object-cover"
            />
            <h1 class="font-bold text-xl line-clamp-1">
              {{ lang === "mn" ? item.title : item.title_en }}
            </h1>
            <p class="line-clamp-5 text-sm font-thin leading-6">
              {{ lang === "mn" ? item.summary : item.summary_en }}
            </p>
            <button
              class="bg-green py-2 w-[10rem] rounded-full text-white text-sm uppercase hover:scale-105"
            >
              {{ lang === "mn" ? "Дэлгэрэнгүй" : "Read more" }}
            </button>
          </router-link>
        </div>

        <div class="flex justify-center w-full my-10">
          <PaginationCart
            :totalPages="totalPages"
            :modelValue="currentPage"
            :style="{
              color: 'green',
              height: 10,
              width: 10,
              fontSize: 'sm',
            }"
            @page-changed="onPageChanged"
          />
        </div>
      </div>

      <div
        v-else-if="news.length === 0 && !isLoading"
        class="flex justify-center items-center w-full h-[10rem] md:text-2xl bg-gray-100"
      >
        {{ lang === "mn" ? "Мэдээ хоосон байна" : "No News Found" }}
      </div>

      <div
        v-else-if="isLoading"
        class="flex justify-center items-center w-full h-[50vh]"
      >
        <SpinLoading />
      </div>

      <NewsPageSide />
    </div>
  </main>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import clientInstance from "@/lib/clientInstance";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import NewsPageSide from "@/containers/MiningMongolia/NewsPageSide.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMNewsList",
  components: { NewsPageSide, PaginationCart, SpinLoading },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const router = useRouter();
    const route = useRoute();
    const news = ref([]);
    const isLoading = ref(false);
    const currentPage = ref(parseInt(route.params.page) || 1);
    const totalPages = ref(1);

    const fetchData = async (term, page) => {
      try {
        isLoading.value = true;
        let res;

        if (!isNaN(parseFloat(term)) && isFinite(term)) {
          res = await clientInstance.get(
            `/get/news?category=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        } else if (/^\d{4}-\d{2}$/.test(term)) {
          res = await clientInstance.get(
            `/get/news-by-date?date=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        } else {
          res = await clientInstance.get(
            `/search/news?term=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        }

        news.value = res.data.news;
        totalPages.value = res.data.totalPages;
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData(route.params.cid || 8, route.params.page || 1);
    });

    watch([() => route.params.cid, lang], ([newCid]) => {
      currentPage.value = 1;
      fetchData(newCid || 8);
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchData(route.params.cid, page);
      router.push(`/mm/blog/${route.params.cid}=${currentPage.value}`);
    };

    return {
      lang,
      news,
      isLoading,
      currentPage,
      totalPages,
      onPageChanged,
    };
  },
};
</script>
