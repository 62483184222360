<template>
  <div class="flex justify-center w-full font-zona">
    <div class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-10">
      <div class="sm:flex gap-5 sm:gap-10">
        <div class="space-y-1">
          <button
            class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400"
          >
            {{ lang === "mn" ? "Бидний тухай" : "About us" }}
          </button>
          <button
            class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400"
          >
            {{ lang === "mn" ? "Бүтэц зохион байгуулалт" : "Organization" }}
          </button>
        </div>

        <div>
          <h1 class="font-bold uppercase text-xl sm:text-2xl max-sm:mt-3">
            {{ lang === "mn" ? "Бидний тухай" : "About us" }}
          </h1>
          <p class="text-justify">
            {{
              lang === "mn"
                ? "Монголын Уул Уурхайн Үндэсний Ассоциаци нь эрдэс баялгийн салбарт үйлдвэрлэл, үйлчилгээ явуулдаг аж ахуйн нэгж, байгууллагуудыг гишүүнчлэлээр баталгаажуулсан, гишүүддээ үйлчилдэг, хараат бус үйл ажиллагаа явуулдаг, ашгийн бус ТББ юм."
                : "Mongolian National Mining Association is an independent, non-profit NGO that serves its members and has certified membership of enterprises and organizations that provide products and services in the field of mineral resources."
            }}
          </p>
        </div>
      </div>

      <h1
        id="1"
        class="font-bold uppercase text-lg sm:text-2xl text-center mt-10"
      >
        {{ lang === "mn" ? "Хүндэт ерөнхийлөгчид" : "Presidents" }}
      </h1>
      <div
        v-motion-pop-visible-once
        :duration="1000"
        class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-x-10 gap-y-5 mt-5 mb-10"
      >
        <div v-for="president in presidents" :key="president.id">
          <div class="flex justify-center w-full">
            <img
              :src="president.profile"
              alt="president"
              class="rounded-full w-36 h-36 object-contain"
            />
          </div>
          <h6 class="font-bold text-center text-lg">{{ president.name }}</h6>
          <h6 class="font-bold text-center my-1">{{ president.position }}</h6>
          <div class="flex justify-center">
            <div class="bg-green max-w-56 w-full p-2 text-white text-center">
              {{ president.years_of_work }}
            </div>
          </div>
        </div>
      </div>

      <img
        src="@/assets/images/miningMongolia/about/1729573307659-313065875.png"
        alt=""
        class="w-full"
      />

      <img
        src="@/assets/images/miningMongolia/about/1729573315109-216638629.png"
        alt=""
        class="w-full"
      />

      <div id="2" v-if="lang === 'mn'" class="mt-20">
        <div class="flex max-md:flex-col md:items-start gap-10 w-full">
          <div class="sm:w-1/3">
            <h1 class="font-bold uppercase text-2xl mb-2">
              Ассоциацийн зөвлөл
            </h1>
            <p class="text-sm">
              Ассоциацийн эрх барих дээд байгууллага нь Бүх гишүүдийн чуулган
              бөгөөд чуулганы чөлөөт цагт Ассоциацийн зөвлөл удирдлагыг
              хэрэгжүүлдэг.
            </p>
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:w-2/3 gap-5"
          >
            <div
              v-for="council in associationCouncil"
              :key="council.id"
              v-motion-fade-visible-once
            >
              <div class="flex justify-center">
                <img
                  v-if="council.profile"
                  :src="council.profile"
                  alt="council"
                  class="rounded-full w-36 h-36 object-contain"
                />
                <div v-else class="w-36 h-36 border rounded-full"></div>
              </div>
              <h6 class="font-bold text-center text-lg">{{ council.name }}</h6>
              <h6 class="font-bold text-center my-1">{{ council.position }}</h6>
              <p class="text-center font-light text-sm">
                {{ council.company }}
              </p>
            </div>
          </div>
        </div>

        <div class="flex max-md:flex-col md:items-start gap-10 w-full my-20">
          <div class="sm:w-1/3">
            <h1 class="font-bold uppercase text-2xl mb-2">Ажлын алба</h1>
            <p class="text-sm">
              Зөвлөлийн 2023 оны 1 дүгээр сарын 27-ны хурлаар ассоциацийн ажлын
              бүтэц орон тоог шинэчлэн баталж 7 хүний бүрэлдэхүүнтэйгээр
              гүйцэтгэх захирлын ажлын алба үйл ажиллагаагаа явуулж байна.
            </p>
          </div>

          <div
            class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 sm:w-2/3 gap-5"
          >
            <div
              v-for="worker in office"
              :key="worker.id"
              v-motion-fade-visible-once
            >
              <div class="flex justify-center">
                <img
                  v-if="worker.profile"
                  :src="worker.profile"
                  alt="worker"
                  class="rounded-full w-36 h-36 object-contain"
                />
                <div v-else class="w-36 h-36 border rounded-full"></div>
              </div>
              <h6 class="font-bold text-center text-lg">{{ worker.name }}</h6>
              <h6 class="font-bold text-center my-1">{{ worker.position }}</h6>
              <p class="text-center font-light text-sm">
                {{ worker.email }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import clientInstance from "@/lib/clientInstance";

export default {
  name: "AboutUs",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const presidents = ref([]);
    const associationCouncil = ref([]);
    const office = ref([]);
    const isLoading = ref(false);

    const fetchAboutUs = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/get/about-us");
        if (res.status === 200) {
          presidents.value = res.data.about.filter(
            (item) => item.about_category === "President"
          );
          associationCouncil.value = res.data.about.filter(
            (item) => item.about_category === "Association"
          );
          office.value = res.data.about.filter(
            (item) => item.about_category === "Office"
          );
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchAboutUs();
    });

    return { lang, presidents, isLoading, associationCouncil, office };
  },
};
</script>
