<template>
  <main class="font-helvetica">
    <div class="w-full">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :autoplay="{
          delay: 5000,
          disableOnInteraction: true,
          pauseOnMouseEnter: true,
        }"
        :pagination="{ clickable: true }"
        class="mySwiper"
      >
        <swiper-slide v-for="(slide, index) in images" :key="index">
          <img :src="slide" alt="image" class="w-full" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="bg-[#f7f7f7] min-h-[50vh]"></div>
  </main>
</template>

<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

export default {
  name: "YellowHomePage",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const images = ref([
      "https://miningmongolia.mn/static/news/1722391941659.png",
      "https://miningmongolia.mn/static/news/1722391946712.png",
      "https://miningmongolia.mn/static/news/1722391950994.png",
    ]);

    return {
      images,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  --swiper-pagination-color: #cc4628;
  --swiper-pagination-bullet-size: 16px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-button-prev: #cc4628;
}
</style>
