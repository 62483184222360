import { createRouter, createWebHistory } from "vue-router";
// import { adminAuth } from "./lib/auth";
// import Cookies from "js-cookie";

import NotFoundPage from "./pages/NotFoundPage.vue";
import AdminLogin from "./pages/admin/AdminLogin.vue";

// Mining Mongolia ********************************
import MMHomePage from "./pages/MiningMongolia/MMHomePage.vue";
import MMNewsList from "./pages/MiningMongolia/CategoryPages/MMNewsList.vue";
import MMStaticPage from "./pages/MiningMongolia/CategoryPages/MMStaticPage.vue";
import MMDetailNews from "./pages/MiningMongolia/CategoryPages/MMDetailNews.vue";
// import MMProjectPage from "./pages/MiningMongolia/CategoryPages/MMProjectPage.vue";
import MMSelfAssessment from "./pages/MiningMongolia/MMAssessment/MMSelfAssessment.vue";
import MMStockData from "./pages/MiningMongolia/MSE/MMStockPage.vue";
import AboutUs from "./pages/MiningMongolia/MMPages/AboutUs.vue";
import AssociationActivity from "./pages/MiningMongolia/MMPages/AssociationActivity.vue";
import AssociationMembers from "./pages/MiningMongolia/MMPages/AssociationMembers.vue";
import AssociationContact from "./pages/MiningMongolia/MMPages/AssociationContact.vue";
import ResponsibleMining from "./pages/MiningMongolia/MMProjects/ResponsibleMining.vue";
import MiningWeDontKnow from "./pages/MiningMongolia/MMProjects/MiningWeDontKnow.vue";
import MiningHundred from "./pages/MiningMongolia/MMProjects/MiningHundred.vue";
import FutureMiners from "./pages/MiningMongolia/MMProjects/FutureMiners.vue";
import MemberOfTheMonth from "./pages/MiningMongolia/MMProjects/MemberOfTheMonth.vue";
import MMAssessment from "./pages/MiningMongolia/MMAssessment/MMAssessment.vue";
import MMMemberAssessment from "./pages/MiningMongolia/MMAssessment/MMMemberAssessment.vue";
import AssociationThirtyYear from "./pages/MiningMongolia/MMPages/AssociationThirtyYear.vue";

// Mining Mongolia Admin ********************************
import MMDashboard from "./pages/admin/MiningMongolia/MMDashboard.vue";
import MMSocialLinks from "./pages/admin/MiningMongolia/MMSocialLinks.vue";
import MMAddNews from "./pages/admin/MiningMongolia/MMPages/MMAddNews.vue";
import MMUpdateNews from "./pages/admin/MiningMongolia/MMPages/MMUpdateNews.vue";
import MMWebCategories from "./pages/admin/MiningMongolia/MMWebCategories.vue";
import MMEditStatic from "./pages/admin/MiningMongolia/MMEditStatic.vue";
import MMOfficeRequest from "./pages/admin/MiningMongolia/MMMembers/MMOfficeRequest.vue";
import MMHomeslides from "./pages/admin/MiningMongolia/MMPages/MMHomeSlides.vue";
import MMAccounts from "./pages/admin/MiningMongolia/MMAccounts.vue";
import MMUpdateAssessment from "./pages/admin/MiningMongolia/MMMembers/MMUpdateAssessment.vue";
import MMAssessmentResult from "./pages/admin/MiningMongolia/MMMembers/MMAssessmentResult.vue";
import MMDocumentPdf from "./pages/admin/MiningMongolia/MMPages/MMDocumentPdf.vue";
import MMMiningVideo from "./pages/admin/MiningMongolia/MMPages/MMMiningVideo.vue";
import MMMSETrade from "./pages/admin/MiningMongolia/MMMembers/MMMSETrade.vue";
import MMAdminAbout from "./pages/admin/MiningMongolia/MMPages/MMAdminAbout.vue";
import MMAdminMembership from "./pages/admin/MiningMongolia/MMPages/MMAdminMembership.vue";
import MMMemberCompanies from "./pages/admin/MiningMongolia/MMMembers/MMMemberCompanies.vue";

// Mining Week 2024 ********************************
import MWHomePage from "./pages/MiningWeek/MW2024/MWHomePage.vue";
import MWAboutPage from "./pages/MiningWeek/MW2024/MWAboutPage.vue";
import MWAgenda from "./pages/MiningWeek/MW2024/MWAgenda.vue";
import MWPresentation from "./pages/MiningWeek/MW2024/MWPresentation.vue";
import MWContactPage from "./pages/MiningWeek/MW2024/MWContactPage.vue";
import MWForm from "./pages/MiningWeek/MW2024/MWForm.vue";
import MWPhotos from "./pages/MiningWeek/MW2024/MWPhotos.vue";

//Mining Week 2023 ********************************
import MW2023HomePage from "./pages/MiningWeek/MW2023/MW2023HomePage.vue";
import MW2023StaticPage from "./pages/MiningWeek/MW2023/MW2023StaticPage.vue";
import MW2023Presentation from "./pages/MiningWeek/MW2023/MW2023Presentation.vue";
import MW2023NewsList from "./pages/MiningWeek/MW2023/MW2023NewsList.vue";

// Mining Week Admin ********************************
import MWWebCategories from "./pages/admin/MiningWeek/MWWebCategories.vue";
import MWSocialLink from "./pages/admin/MiningWeek/MWSocialLink.vue";
import MWUpdateFooter from "./pages/admin/MiningWeek/MWUpdateFooter.vue";
import MWUpdateHome from "./pages/admin/MiningWeek/MWUpdateHome.vue";
import MWAddNews from "./pages/admin/MiningWeek/MWAddNews.vue";
import MWUpdateNews from "./pages/admin/MiningWeek/MWUpdateNews.vue";
import MWRegistration from "./pages/admin/MiningWeek/MWRegistration.vue";
import MWNewRegistration from "./pages/admin/MiningWeek/MWNewRegistration.vue";
import MWEventPresenters from "./pages/admin/MiningWeek/MWEventPresenters.vue";
import MWUpdateAgenda from "./pages/admin/MiningWeek/MWUpdateAgenda.vue";
import MWEventDates from "./pages/admin/MiningWeek/MWEventDates.vue";
import MWEditPresentation from "./pages/admin/MiningWeek/MWEditPresentation.vue";
import MWGallery from "./pages/admin/MiningWeek/MWGallery.vue";

import MWTest from "./pages/MiningWeek/MW2024/MWTest.vue";

// Yellow page ********************************
import AdminYellowPage from "./pages/yellow-page/AdminYellowPage.vue";
// import YellowPage from "./pages/yellow-page/YellowPage.vue";
import YellowHomePage from "./pages/yellow-page/YellowHomePage.vue";
import YellowCategoryPage from "./pages/yellow-page/Category/YellowCategoryPage.vue";
import YellowDetailPage from "./pages/yellow-page/Category/YellowDetailPage.vue";
import YellowRegistration from "./pages/yellow-page/Registration/YellowRegistration.vue";
import YellowContact from "./pages/yellow-page/Registration/YellowContact.vue";
import AdminUpdateCompany from "./pages/yellow-page/AdminUpdateCompany.vue";

const miningMongoliaRoutes = [
  {
    path: "/mm",
    name: "MMHomePage",
    component: MMHomePage,
  },
  {
    path: "/mm/category/:cid",
    name: "MMStaticPage",
    component: MMStaticPage,
  },
  {
    path: "/mm/blog/:cid=:page",
    name: "MMNewsList",
    component: MMNewsList,
  },
  {
    path: "/mm/detail/:id",
    name: "MMDetailNews",
    component: MMDetailNews,
  },
  // {
  //   path: "/mm/page/:cid",
  //   name: "MMProjectPage",
  //   component: MMProjectPage,
  // },
  {
    path: "/mm/assessment",
    name: "MMAssessment",
    component: MMAssessment,
  },
  {
    path: "/mm/self-assessment",
    name: "MMSelfAssessment",
    component: MMSelfAssessment,
  },
  {
    path: "/mm/member-assessment",
    name: "MMMemberAssessment",
    component: MMMemberAssessment,
  },
  {
    path: "/mm/trade",
    name: "MMStockData",
    component: MMStockData,
  },
  {
    path: "/mm/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/mm/activity",
    name: "AssociationActivity",
    component: AssociationActivity,
  },
  {
    path: "/mm/membership",
    name: "AssociationMembers",
    component: AssociationMembers,
  },
  {
    path: "/mm/contact",
    name: "AssociationContact",
    component: AssociationContact,
  },
  {
    path: "/mm/page/20",
    name: "ResponsibleMining",
    component: ResponsibleMining,
  },
  {
    path: "/mm/page/21",
    name: "MiningWeDontKnow",
    component: MiningWeDontKnow,
  },
  {
    path: "/mm/page/22",
    name: "MiningHundred",
    component: MiningHundred,
  },
  {
    path: "/mm/page/23",
    name: "FutureMiners",
    component: FutureMiners,
  },
  {
    path: "/mm/page/24",
    name: "MemberOfTheMonth",
    component: MemberOfTheMonth,
  },
  {
    path: "/mm/anniversary",
    name: "AssociationThirtyYear",
    component: AssociationThirtyYear,
  },
];

const miningWeekRoutes = [
  {
    path: "/mw",
    name: "MWHomePage",
    component: MWHomePage,
  },
  {
    path: "/mw/about",
    name: "MWAboutPage",
    component: MWAboutPage,
  },
  {
    path: "/mw/agenda",
    name: "MWAgenda",
    component: MWAgenda,
  },
  {
    path: "/mw/presentation",
    name: "MWPresentation",
    component: MWPresentation,
  },
  {
    path: "/mw/contact",
    name: "MWContactPage",
    component: MWContactPage,
  },
  {
    path: "/mw/register",
    name: "MWForm",
    component: MWForm,
  },
  {
    path: "/mw/gallery",
    name: "MWPhotos",
    component: MWPhotos,
  },
  {
    path: "/mw/2023",
    name: "MW2023HomePage",
    component: MW2023HomePage,
  },
  {
    path: "/mw/2023/presentation",
    name: "MW2023Presentation",
    component: MW2023Presentation,
  },
  {
    path: "/mw/2023/category/:cid",
    name: "MW2023StaticPage",
    component: MW2023StaticPage,
  },
  {
    path: "/mw/2023/news",
    name: "MW2023NewsList",
    component: MW2023NewsList,
  },
  {
    path: "/mw/test",
    name: "MWTest",
    component: MWTest,
  },
];

const adminRoutes = [
  {
    path: "/admin/login",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/admin/dashboard",
    name: "MMDashboard",
    component: MMDashboard,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/link",
    name: "MMWebUrl",
    component: MMWebCategories,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/add-news",
    name: "MMAddNews",
    component: MMAddNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/update-news",
    name: "MMUpdateNews",
    component: MMUpdateNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/social-links",
    name: "MMSocialLinks",
    component: MMSocialLinks,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/edit-static/:cid",
    name: "MMEditStatic",
    component: MMEditStatic,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/request-list",
    name: "MMOfficeRequest",
    component: MMOfficeRequest,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/slides",
    name: "MMHomeSlides",
    component: MMHomeslides,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/accounts",
    name: "MMAccounts",
    component: MMAccounts,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/update-assessment",
    name: "MMUpdateAssessment",
    component: MMUpdateAssessment,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/assessment-result",
    name: "MMAssessmentResult",
    component: MMAssessmentResult,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/document",
    name: "MMDocumentPdf",
    component: MMDocumentPdf,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/video",
    name: "MMMiningVideo",
    component: MMMiningVideo,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/trade",
    name: "MMMSETrade",
    component: MMMSETrade,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/about",
    name: "MMAdminAbout",
    component: MMAdminAbout,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/membership",
    name: "MMAdminMembership",
    component: MMAdminMembership,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/companies",
    name: "MMMemberCompanies",
    component: MMMemberCompanies,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
];

const adminMWRoutes = [
  {
    path: "/admin/week/link",
    name: "MWWebUrl",
    component: MWWebCategories,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/social-link",
    name: "MWSocialLink",
    component: MWSocialLink,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/footer",
    name: "MWUpdateFooter",
    component: MWUpdateFooter,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/home",
    name: "MWUpdateHome",
    component: MWUpdateHome,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/add-news",
    name: "MWAddNews",
    component: MWAddNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/update-news",
    name: "MWUpdateNews",
    component: MWUpdateNews,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/register",
    name: "MWRegistration",
    component: MWRegistration,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/new-register",
    name: "MWNewRegistration",
    component: MWNewRegistration,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/presenters",
    name: "MWEventPresenters",
    component: MWEventPresenters,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/agenda",
    name: "MWUpdateAgenda",
    component: MWUpdateAgenda,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/event-date",
    name: "MWEventDates",
    component: MWEventDates,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/presentation",
    name: "MWEditPresentation",
    component: MWEditPresentation,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/week/gallery",
    name: "MWGallery",
    component: MWGallery,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
];

const yellowRoutes = [
  {
    path: "/yellowpage",
    name: "YellowPage",
    component: YellowHomePage,
    meta: { title: "Лавлах" },
  },
  {
    path: "/yellowpage/category/:id",
    name: "YellowCategoryPage",
    component: YellowCategoryPage,
    meta: { title: "Лавлах" },
  },
  {
    path: "/yellowpage/detail/:id",
    name: "YellowDetailPage",
    component: YellowDetailPage,
    meta: { title: "Лавлах" },
  },
  {
    path: "/yellowpage/register",
    name: "YellowRegistration",
    component: YellowRegistration,
    meta: { title: "Лавлах" },
  },
  {
    path: "/yellowpage/contact",
    name: "YellowContact",
    component: YellowContact,
    meta: { title: "Лавлах" },
  },
  {
    path: "/admin/yellow-page/add",
    name: "AdminYellowPage",
    component: AdminYellowPage,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
  {
    path: "/admin/yellow-page/update",
    name: "AdminUpdateCompany",
    component: AdminUpdateCompany,
    meta: { title: "Админ", requiresAdminAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/:pathMatch(.*)*", name: "Page404", component: NotFoundPage },
    ...miningMongoliaRoutes,
    ...miningWeekRoutes,
    ...adminRoutes,
    ...adminMWRoutes,
    ...yellowRoutes,
  ],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to) => {
  if (to.path.includes("/mm")) {
    document.title = "Монголын Уул Уурхайн Үндэсний Ассоциаци";
  } else if (to.path.includes("/mw")) {
    document.title = "Mining Week";
  }

  // if (to.path === "/admin" || to.path === "/admin/") {
  //   next("/admin/login");
  //   return;
  // }

  // if (to.matched.some((record) => record.meta.requiresAdminAuth)) {
  //   const authenticated = await adminAuth();
  //   if (authenticated) {
  //     if (to.path === "/admin/login") {
  //       next("/admin/dashboard");
  //       return;
  //     }
  //     next();
  //     return;
  //   }

  //   next("/admin/login");
  // } else {
  //   if (to.path === "/admin/login") {
  //     if (Cookies.get("token")) {
  //       next("/admin/dashboard");
  //       return;
  //     }
  //   }
  //   next();
  // }
});

export default router;
