<template>
  <div
    class="flex flex-col items-center bg-[#09396C] text-sm text-white text-nowrap"
  >
    <footer class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5 z-10">
      <div class="flex max-md:flex-col py-5 max-md:gap-5">
        <div class="w-3/4">
          <h6 class="uppercase mb-3">
            {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
          </h6>
          <ul
            class="flex max-md:flex-col justify-between gap-5 md:gap-10 mr-20 space-y-1"
          >
            <li class="flex flex-col gap-1">
              <a
                v-for="phone in phoneList"
                :key="phone.id"
                :href="`tel:${phone.information}`"
                class="flex items-center gap-1"
              >
                <font-awesome-icon icon="fa-solid fa-phone" />
                {{ phone.information }}
              </a>
            </li>

            <li class="flex flex-col gap-1">
              <a
                v-for="email in emailList"
                :key="email.id"
                :href="`mailto:${email.information}`"
                class="flex items-center gap-1"
              >
                <font-awesome-icon icon="fa-solid fa-envelope" />
                {{ email.information }}
              </a>
            </li>

            <li class="flex flex-col gap-1">
              <a
                v-for="link in linkList"
                :key="link.id"
                :href="`https://${link.information}`"
                target="_blank"
                class="flex items-center gap-1"
              >
                <font-awesome-icon icon="fa-solid fa-globe" />
                {{ link.information }}
              </a>
            </li>
          </ul>
        </div>

        <div>
          <h6 class="uppercase mb-3">
            {{ lang === "mn" ? "Сошиал медиа" : "Social media" }}
          </h6>
          <div class="flex items-center gap-2">
            <a
              href="https://www.facebook.com/miningweek"
              target="_blank"
              class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#CC4628]"
            >
              <font-awesome-icon icon="fa-brands fa-facebook-f" />
            </a>
            <a
              href="https://www.instagram.com/miningweek"
              target="_blank"
              class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#CC4628]"
            >
              <font-awesome-icon icon="fa-brands fa-instagram" />
            </a>
            <a
              href="https://twitter.com/mining_week"
              target="_blank"
              class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#CC4628]"
            >
              <font-awesome-icon icon="fa-brands fa-x-twitter" />
            </a>
            <a
              href="https://www.linkedin.com/company/mongolian-national-mining-association"
              target="_blank"
              class="flex justify-center items-center w-8 h-8 rounded-full border hover:bg-[#CC4628]"
            >
              <font-awesome-icon icon="fa-brands fa-linkedin-in" />
            </a>
          </div>
        </div>
      </div>

      <div class="border-t border-white text-center py-5">
        <a href="https://bowsys.mn" target="_blank" class="hover:underline"
          >Copyright by &copy; BOW LLC - 2025</a
        >
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "MW2023Footer",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const footerInfo = ref([
      {
        type: "phone",
        information: "+976 7011 0163",
      },
      {
        type: "email",
        information: "info@miningmongolia.mn",
      },
      {
        type: "link",
        information: "www.miningmongolia.mn",
      },
    ]);

    const phoneList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "phone");
    });
    const emailList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "email");
    });
    const linkList = computed(() => {
      return footerInfo.value.filter((item) => item.type === "link");
    });

    return { phoneList, emailList, linkList, lang };
  },
};
</script>
