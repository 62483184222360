<template>
  <header
    class="block md:hidden bg-white sticky top-0 z-10 font-helvetica"
    :class="isScrolled ? 'py-2 px-3' : 'py-3 px-5'"
  >
    <div class="flex justify-center w-full gap-3">
      <router-link
        to="/yellowpage/contact"
        class="bg-[#09396C] text-white text-sm transition-all duration-300"
        :class="isScrolled ? 'p-1' : 'p-2'"
      >
        {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
      </router-link>
      <router-link
        to="/yellowpage/register"
        class="bg-[#CC4628] text-white text-sm transition-all duration-300"
        :class="isScrolled ? 'p-1' : 'p-2'"
      >
        {{ lang === "mn" ? "Мэдээлэл нэмэх" : "Send request" }}
      </router-link>
    </div>

    <div class="flex justify-between items-center mt-3">
      <button
        @click="toggleLanguage()"
        class="transition-all duration-300 hover:text-[#09396C]"
        :class="isScrolled ? 'text-base' : 'text-lg'"
      >
        <font-awesome-icon icon="fa-solid fa-globe" />
      </button>
      <router-link to="/yellowpage" class="uppercase text-3xl text-[#CC4628]">{{
        lang === "mn" ? "Лого" : "Logo"
      }}</router-link>
      <button @click="toggleDrawer()" class="text-lg">
        <font-awesome-icon icon="fa-solid fa-bars" />
      </button>
    </div>
  </header>

  <transition name="fade" class="md:hidden">
    <div
      v-if="isDrawerOpen"
      class="fixed top-0 right-0 z-20 h-screen w-[18rem] bg-white text-sm p-5"
    >
      <button @click="toggleDrawer()" class="absolute top-3 right-3">
        <font-awesome-icon icon="fa-solid fa-xmark" class="text-xl" />
      </button>

      <ul class="space-y-5 mt-8">
        <li
          v-for="(route, index) in routes"
          :key="index"
          class="flex justify-between"
        >
          <router-link
            @click="toggleDrawer"
            :to="{
              name: 'YellowCategoryPage',
              params: { id: route.id },
            }"
            class="uppercase hover:text-[#CC4628]"
          >
            <font-awesome-icon icon="fa-solid fa-list" class="mr-0.5" />
            {{ lang === "mn" ? route.name : route.name_en }}
          </router-link>
          <font-awesome-icon
            icon="fa-solid fa-chevron-right"
            class="text-[#CC4628]"
          />
        </li>
      </ul>
    </div>
  </transition>

  <header
    v-motion-slide-visible-once-top
    :enter="{
      transition: {
        delay: 3000,
        duration: 3000,
      },
    }"
    class="hidden md:flex justify-between items-center sticky top-0 bg-white gap-10 py-5 px-10 font-helvetica z-10"
  >
    <router-link
      to="/yellowpage"
      class="w-1/4 uppercase text-6xl text-[#CC4628]"
      >{{ lang === "mn" ? "Лого" : "Logo" }}</router-link
    >
    <div class="w-3/4">
      <div class="flex justify-end gap-3">
        <router-link
          to="/yellowpage/contact"
          class="bg-[#09396C] text-white transition-all duration-300"
          :class="isScrolled ? 'p-2 text-sm' : 'px-2 py-4'"
        >
          {{ lang === "mn" ? "Холбоо барих" : "Contact" }}
        </router-link>
        <router-link
          to="/yellowpage/register"
          class="bg-[#CC4628] text-white transition-all duration-300"
          :class="isScrolled ? 'p-2 text-sm' : 'px-2 py-4'"
        >
          {{ lang === "mn" ? "Мэдээлэл нэмэх" : "Send request" }}
        </router-link>
      </div>

      <nav class="flex justify-end items-center gap-5 mt-5">
        <router-link
          v-for="(route, index) in routes"
          :key="index"
          :to="{
            name: 'YellowCategoryPage',
            params: { id: route.id },
          }"
          class="uppercase hover:text-[#CC4628]"
        >
          {{ lang === "mn" ? route.name : route.name_en }}
          <font-awesome-icon
            icon="fa-solid fa-chevron-down"
            class="ml-0.5 text-[#CC4628]"
          />
        </router-link>
        <button
          @click="toggleLanguage()"
          class="transition-all duration-300 hover:text-[#09396C]"
          :class="isScrolled ? 'text-base' : 'text-lg'"
        >
          <font-awesome-icon icon="fa-solid fa-globe" />
        </button>
      </nav>
    </div>
  </header>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowNavbar",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const toggleLanguage = () => {
      store.dispatch("toggleLanguage");
    };

    const routes = ref([
      {
        name: "Уул уурхайн компани",
        name_en: "Mining companies",
        to: "/yellowpage/category",
        id: "01",
      },
      {
        name: "Ханган нийлүүлэгч",
        name_en: "Supplier companies",
        to: "/yellowpage/supplier-companies",
        id: "02",
      },
      {
        name: "Үйлчилгээ үзүүлэгч",
        name_en: "Service companies",
        to: "/yellowpage/service-companies",
        id: "03",
      },
      {
        name: "Бусад",
        name_en: "Others",
        to: "/yellowpage/others",
        id: "04",
      },
    ]);

    const isScrolled = ref(false);

    const handleScroll = () => {
      isScrolled.value = window.scrollY > 50;
    };

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    const isDrawerOpen = ref(false);
    const toggleDrawer = () => {
      isDrawerOpen.value = !isDrawerOpen.value;
    };

    return {
      routes,
      isScrolled,
      lang,
      toggleLanguage,
      isDrawerOpen,
      toggleDrawer,
    };
  },
};
</script>
