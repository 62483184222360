<template>
  <main class="flex justify-center w-full font-zona">
    <div class="w-full min-[1200px]:w-4/5 max-[1199px]:p-5">
      <div
        v-if="noContent"
        class="min-h-[50vh] flex justify-center items-center md:text-2xl text-gray-400"
      >
        {{ lang === "mn" ? "Хуудас хоосон байна" : "No Content" }}
      </div>

      <div v-else-if="!noContent && !isLoading" class="space-y-10 my-5">
        <div v-for="data in staticData" :key="data.cid" :id="data.category">
          <!-- Static хуудас -->
          <div class="prose" style="max-width: 100%">
            <p
              v-if="lang === 'mn' ? data.text : data.text_en"
              v-html="lang === 'mn' ? data.text : data.text_en"
            ></p>
          </div>

          <!-- Projects -->
          <div
            v-if="data.cid === 6"
            class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10"
          >
            <MMProjects />
          </div>

          <!-- New member request -->
          <div
            v-if="data.cid === 12"
            class="my-10 border p-5"
            :class="
              success
                ? 'border-[#4BB543] shadow shadow-[#4BB543]'
                : 'border-slate-300'
            "
          >
            <h1
              class="font-bold text-center text-xl md:tracking-widest uppercase mb-1"
            >
              {{ lang === "mn" ? "Хүсэлт илгээх" : "Send a Request" }}
            </h1>
            <p
              class="max-md:text-sm text-center font-light text-gray-700 mb-10"
            >
              {{
                lang === "mn"
                  ? "Та доорх талбараар дамжуулж Ассоциацийн ажлын албад хүсэлт гаргах боломжтой."
                  : "You can submit a request to the MNMA Office through the fields below."
              }}
            </p>

            <div class="flex max-sm:flex-col gap-5 sm:gap-10">
              <div class="w-full sm:w-1/2 space-y-2 text-slate-500">
                <div
                  class="flex items-center border border-slate-300 py-2 px-6 placeholder:max-sm:text-xs"
                >
                  <input
                    name="name"
                    id="name"
                    type="text"
                    :placeholder="lang === 'mn' ? 'Овог нэр' : 'Company name'"
                    class="w-full max-sm:placeholder:text-sm"
                    v-model="membershipForm.name"
                  />
                  <font-awesome-icon icon="fa-regular fa-user" class="ml-6" />
                </div>

                <div
                  class="flex items-center border border-slate-300 py-2 px-6"
                >
                  <input
                    name="email"
                    id="email"
                    type="email"
                    :placeholder="
                      lang === 'mn' ? 'И-мэйл хаяг' : 'E-mail address'
                    "
                    class="w-full max-sm:placeholder:text-sm"
                    v-model="membershipForm.email"
                  />
                  <font-awesome-icon
                    icon="fa-regular fa-envelope"
                    class="ml-6"
                  />
                </div>

                <div
                  class="flex items-center border border-slate-300 py-2 px-6 max-sm:placeholder:text-xs"
                >
                  <input
                    name="phone_number"
                    id="phone_number"
                    type="tel"
                    :placeholder="
                      lang === 'mn' ? 'Утасны дугаар' : 'Phone Number'
                    "
                    class="w-full max-sm:placeholder:text-sm"
                    v-model="membershipForm.phone_number"
                  />
                  <img
                    src="@/assets/svg/phone-line-icon.svg"
                    class="h-[1rem] w-[1rem] ml-6"
                  />
                </div>

                <div
                  class="flex items-center border border-slate-300 py-2 px-6 max-sm:placeholder:text-xs"
                >
                  <input
                    name="business_type"
                    id="business_type"
                    type="text"
                    :placeholder="
                      lang === 'mn' ? 'Бизнесийн төрөл' : 'Type of Business'
                    "
                    class="w-full max-sm:placeholder:text-sm"
                    v-model="membershipForm.business_type"
                  />
                  <font-awesome-icon
                    icon="fa-regular fa-clipboard"
                    class="ml-6"
                  />
                </div>
              </div>

              <div
                class="flex flex-col gap-3 justify-between max-sm:text-sm w-full sm:w-1/2"
              >
                <div class="space-y-2">
                  <span>{{
                    lang === "mn"
                      ? "Бид танд яаж туслах вэ?"
                      : "How can we help you?"
                  }}</span>
                  <div class="flex items-center gap-3 ml-3">
                    <input
                      name="member_request"
                      id="member_request"
                      type="checkbox"
                      v-model="membershipForm.member_request"
                    />{{
                      lang === "mn" ? "Гишүүнээр элсэх" : "Become a member"
                    }}
                  </div>
                  <div class="flex items-center gap-3 ml-3">
                    <input
                      name="codex_request"
                      id="codex_request"
                      type="checkbox"
                      v-model="membershipForm.codex_request"
                    />{{
                      lang === "mn"
                        ? "ХУУ кодекс нэгдэх"
                        : "Join the Responsible Mining CODEX"
                    }}
                  </div>
                  <div class="flex items-center gap-3 ml-3">
                    <input
                      name="meeting_request"
                      id="meeting_request"
                      type="checkbox"
                      v-model="membershipForm.meeting_request"
                    />{{
                      lang === "mn"
                        ? "Уулзалтын цаг товлох"
                        : "Make an appointment"
                    }}
                  </div>
                </div>

                <button
                  @click="sendRequest()"
                  type="submit"
                  class="text-white p-2 w-[10rem] hover:before:bg-lightGreen relative overflow-hidden bg-green transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-lightGreen before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full"
                >
                  <span class="relative z-10">
                    {{ lang === "mn" ? "Илгээх" : "Send" }}</span
                  >
                </button>
              </div>
            </div>

            <h6
              v-if="success"
              class="text-center text-lg text-[#4BB543] uppercase mt-5"
            >
              {{
                lang === "mn"
                  ? "Хүсэлт амжилттай илгээгдлээ"
                  : "Request was sent successfully!"
              }}
            </h6>
          </div>
        </div>
      </div>

      <div
        v-else-if="!noContent && isLoading"
        class="flex justify-center items-center w-full h-[50vh]"
      >
        <SpinLoading />
      </div>
    </div>
  </main>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import clientInstance from "@/lib/clientInstance";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import MMProjects from "@/containers/MiningMongolia/MMProjects.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMStaticPage",
  components: { MMProjects, SpinLoading },
  setup() {
    const route = useRoute();
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(true);
    const staticData = ref([]);
    const noContent = ref(false);

    const fetchData = async (categoryId) => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/get/static-page?pid=${categoryId}`
        );
        staticData.value = res.data.static;
        checkNoContent();
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    const checkNoContent = () => {
      if (lang.value === "mn") {
        noContent.value = staticData.value.every((data) => !data.text);
      } else {
        noContent.value = staticData.value.every((data) => !data.text_en);
      }
    };

    onMounted(() => {
      fetchData(route.params.cid);
    });

    watch(
      () => route.params.cid,
      (newCid) => {
        fetchData(newCid);
      }
    );

    watch(lang, () => {
      checkNoContent();
    });

    const isRequestLoading = ref(false);
    const success = ref("");
    const membershipForm = ref({
      name: "",
      email: "",
      phone_number: "",
      business_type: "",
      member_request: false,
      codex_request: false,
      meeting_request: false,
    });

    const resetForm = () => {
      membershipForm.value = {
        name: "",
        email: "",
        phone_number: "",
        business_type: "",
        member_request: false,
        codex_request: false,
        meeting_request: false,
      };
    };

    const isValid = () => {
      if (!membershipForm.value.name) {
        return alert("Овог нэрээ оруулна уу");
      }
      if (!membershipForm.value.email) {
        return alert("Имэйл хаягаа оруулна уу");
      }
      if (!membershipForm.value.phone_number) {
        return alert("Утасны дугаараа оруулна уу");
      }
      if (!membershipForm.value.business_type) {
        return alert("Бизнесийн төрлөө оруулна уу");
      }
      if (
        !membershipForm.value.member_request &&
        !membershipForm.value.codex_request &&
        !membershipForm.value.meeting_request
      ) {
        return alert("Хүсэлтийн зорилгийг бөглөнө үү");
      }
      return true;
    };

    const sendRequest = async () => {
      if (!isValid()) return;

      try {
        isRequestLoading.value = true;
        const res = await clientInstance.post(
          "/insert/office-request",
          membershipForm.value
        );
        if (res.status === 200) {
          success.value = "Хүсэлт амжилттай илгээгдлээ.";
          resetForm();

          setTimeout(() => {
            success.value = null;
          }, 10000);
        }
      } catch (err) {
        return err;
      } finally {
        isRequestLoading.value = false;
      }
    };

    return {
      lang,
      isLoading,
      staticData,
      noContent,
      membershipForm,
      sendRequest,
      success,
    };
  },
};
</script>
