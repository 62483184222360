<template>{{ company }}</template>

<script>
export default {
  name: "AdminUpdateCompany",
  props: {
    company: Object,
  },
  setup() {
    return {};
  },
};
</script>
