<template>
  <div
    class="flex max-sm:flex-col items-center justify-center gap-2 sm:gap-5 text-xs sm:text-sm font-zona"
  >
    <div class="flex items-center">
      <p class="bg-gray-200 border border-gray-200 rounded-l p-2">
        {{ lang === "mn" ? "Эхлэх огноо" : "Start Date" }}
      </p>
      <input
        type="date"
        class="border border-gray-200 rounded-r p-1.5"
        :max="maxStartDate"
        v-model="startDate"
      />
    </div>

    <div class="flex items-center">
      <p class="bg-gray-200 border border-gray-200 rounded-l p-2">
        {{ lang === "mn" ? "Дуусах огноо" : "End Date" }}
      </p>
      <input
        type="date"
        class="border border-gray-200 rounded-r p-1.5"
        :min="minEndDate"
        v-model="endDate"
      />
    </div>
  </div>

  <div v-if="!isLoading && tradeData.length > 0" class="mt-10">
    <div
      class="flex max-sm:flex-col max-sm:gap-2 justify-between items-center max-sm:items-start text-sm mb-5"
    >
      <div class="space-x-3">
        <span>{{ lang === "mn" ? "Хуудас бүрт:" : "Per page:" }}</span>
        <select
          name="dataPerPage"
          id="dataPerPage"
          class="border rounded p-1 text-blue-500"
          v-model="dataPerPage"
        >
          <option value="10">10</option>
          <option value="20">20</option>
          <option value="30">30</option>
        </select>
      </div>

      <span
        >{{ lang === "mn" ? "Нийт арилжааны тоо:" : "Total Trades:" }}
        {{ totalTradeCount }}
      </span>

      <div class="flex items-center gap-3">
        <span
          >{{ lang === "mn" ? "Нийт хуудас:" : "Pages:" }} {{ totalPages }}
        </span>
        <PaginationCart
          :totalPages="totalPages"
          :modelValue="currentPage"
          :style="{
            color: 'blue-500',
            height: 7,
            width: 7,
            fontSize: 'xs',
          }"
          @page-changed="onPageChanged"
        />
      </div>
    </div>

    <h1 class="text-end text-xs text-black italic mb-1">
      "{{ startDate }}"-аас "{{ endDate }}"-ний өдрийн арилжааны мэдээлэл
    </h1>
    <hot-table :settings="hotSettings" :data="tradeData"></hot-table>
  </div>

  <div
    v-else-if="!isLoading && tradeData.length === 0"
    class="flex justify-center items-center h-[40vh]"
  >
    {{
      lang === "mn"
        ? "Хайсан хугацаан дахь өгөгдөл олдсонгүй."
        : "No Data was found"
    }}
  </div>

  <div v-else-if="isLoading" class="flex justify-center items-center h-[50vh]">
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { onMounted, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "MMTradeData",
  components: {
    SpinLoading,
    PaginationCart,
    HotTable,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const isLoading = ref(false);
    const tradeData = ref([]);

    const dataPerPage = ref(10);
    const totalTradeCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const startDate = ref(formatDate(sevenDaysAgo));
    const endDate = ref(formatDate(today));

    const fetchTradeData = async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/get/trade?startDate=${startDate.value}&endDate=${endDate.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (res.status === 200) {
          tradeData.value = res.data.trade;
          totalPages.value = res.data.totalPages;
          totalTradeCount.value = res.data.total;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchTradeData();
    });

    const maxStartDate = computed(() => endDate.value || today);
    const minEndDate = computed(() => startDate.value || today);

    watch([startDate, endDate], ([newStartDate, newEndDate]) => {
      if (newStartDate > newEndDate) {
        endDate.value = newStartDate;
      } else if (newEndDate < newStartDate) {
        startDate.value = newEndDate;
      }
    });

    watch([startDate, endDate, dataPerPage], () => {
      currentPage.value = 1;
      fetchTradeData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchTradeData();
    };

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-1 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const columnHeaders = {
      mn: [
        "Худалдагч",
        "Бүтээгдэхүүн",
        "Доод үнэ",
        "Хэлцлийн үнэ",
        "Зохион байгуулагдсан огноо",
        "Дэлгэрэнгүй",
      ],
      en: [
        "Seller",
        "Product",
        "Minimum Price",
        "Deal Price",
        "Datetime",
        "Details",
      ],
    };

    const colHeaders = computed(() => {
      return columnHeaders[lang.value] || columnHeaders["mn"];
    });

    const hotSettings = {
      colHeaders: colHeaders.value,
      columns: [
        {
          data: "seller_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "product_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "min_price",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "price_currency",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "trade_datetime",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          renderer: function (inst, td, row) {
            const own_url = tradeData.value[row].own_url;
            const url = tradeData.value[row].url;
            const link = own_url ? own_url : url;
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-1">
                  <a href=${link} target="_blank" style="color:#3b82f6;">Харах</a>
              </div>`;
          },
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "60vh",
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    return {
      lang,
      isLoading,
      tradeData,
      startDate,
      endDate,
      hotSettings,
      dataPerPage,
      totalPages,
      totalTradeCount,
      currentPage,
      onPageChanged,
      maxStartDate,
      minEndDate,
    };
  },
};
</script>
