<template>
  <div class="flex justify-center min-h-[50vh] font-zona">
    <main class="w-full min-[1200px]:w-4/5 max-[1199px]:p-3 lg:my-10">
      <div v-if="currentPage === 1">
        <router-link
          to="/mm/assessment"
          class="text-slate-300 text-xs hover:text-slate-500"
          ><font-awesome-icon
            icon="fa-solid fa-angle-left"
            class="mr-1"
          />Буцах</router-link
        >
        <h1 class="uppercase font-semibold text-blue-500 text-xl mt-2">
          Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
        </h1>

        <div class="text-sm w-full sm:w-1/2">
          <p class="text-xs text-slate-700 mb-5">
            МУУҮА-д гишүүнээр бүртгэлтэй и-мэйл хаягаа оруулна уу.
          </p>

          <div
            class="flex items-center border rounded w-full text-slate-500 max-sm:placeholder:text-xs"
            :class="
              invalid || unregisteredEmail
                ? 'border-red-500'
                : 'border-slate-300 mb-4'
            "
          >
            <font-awesome-icon
              icon="fa-solid fa-envelope"
              class="text-base mr-3 pl-3"
            />
            <input
              id="email"
              name="email"
              type="email"
              v-model="form.email"
              placeholder="И-мэйл хаяг"
              class="w-full p-3 rounded"
            />
          </div>
          <p v-if="invalid && !form.email" class="text-red-500 text-xs mb-4">
            Та и-мэйл хаягаа оруулна уу.
          </p>
          <p
            v-if="unregisteredEmail && !invalid"
            class="text-red-500 text-xs mb-4"
          >
            Таны оруулсан и-мэйл хаяг бүртгэлгүй байна. МУУҮА-тай холбогдоно уу.
            <router-link
              to="/mm/contact"
              class="text-gray-500 underline hover:text-blue-500"
              >Холбоо барих</router-link
            >
          </p>

          <button
            @click="checkEmail()"
            class="text-white p-2 rounded-lg bg-blue-500 enabled:hover:bg-blue-600 disabled:cursor-not-allowed"
          >
            Баталгаажуулах код илгээх
          </button>
        </div>
      </div>

      <div
        v-else-if="currentPage === 2"
        class="flex flex-col items-center w-full"
        @keydown.enter="checkVerificationCode"
      >
        <h1
          class="uppercase font-semibold text-blue-500 text-lg sm:text-xl text-center mb-3"
        >
          Баталгаажуулах код
        </h1>
        <p class="sm:w-1/2 text-sm text-center text-slate-500 mb-8">
          Таны {{ form.email }} и-мэйл хаягт ирүүлсэн баталгаажуулах кодыг доор
          оруулна уу.
        </p>

        <div class="flex gap-x-1 sm:gap-x-3 mb-2">
          <input
            v-for="(digit, index) in otp"
            :key="index"
            type="text"
            class="w-10 h-10 sm:w-12 sm:h-12 lg:w-14 lg:h-14 border rounded font-bold lg:text-lg text-center"
            maxlength="1"
            inputmode="numeric"
            :ref="(el) => (otpRefs[index] = el)"
            v-model="otp[index]"
            @input="handleInput(index)"
            @keydown.backspace="handleBackspace(index, $event)"
          />
        </div>

        <p v-if="invalidCode" class="text-red-500 text-xs">{{ invalidCode }}</p>
        <button
          @click="getVerificationCodeAgain"
          class="text-center text-xs text-slate-400 mb-8 underline hover:text-slate-500"
        >
          Дахин код авах?
        </button>

        <button
          @click="checkVerificationCode"
          class="text-white p-2 rounded-lg bg-blue-500 enabled:hover:bg-blue-600 disabled:cursor-not-allowed"
          :disabled="!isOtpComplete"
        >
          Үргэлжлүүлэх
        </button>
      </div>

      <div v-else-if="currentPage === 3 && !isLoading">
        <MMAssessmentTable
          :email="form.email"
          :member="member"
          :memberId="memberId"
          :answerId="answerId"
          :result="result"
          :finished="assessmentCompletion"
        />
      </div>

      <div v-else class="flex justify-center items-center">
        <SpinLoading />
      </div>
    </main>
  </div>

  <SuccessModal
    v-if="success"
    :success="success"
    :result="result"
    @close-alert="closeAlert"
  />
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { computed, ref, watch, nextTick } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import MMAssessmentTable from "@/containers/MiningMongolia/MMAssessmentTable.vue";

export default {
  name: "MMMemberAssessment",
  components: {
    SuccessModal,
    SpinLoading,
    MMAssessmentTable,
  },
  setup() {
    const currentPage = ref(1);
    const invalid = ref(false);
    const unregisteredEmail = ref(false);
    const form = ref({
      email: null,
    });
    const memberId = ref(null);
    const member = ref(null);
    const invalidCode = ref(null);
    const isLoading = ref(false);
    const result = ref(false);
    const answerId = ref(null);
    const assessmentCompletion = ref(false);

    const checkEmail = async () => {
      if (!form.value.email) {
        invalid.value = true;
      }

      try {
        isLoading.value = true;
        const res = await clientInstance.get(
          `/check/email?email=${form.value.email}`
        );

        if (res.status === 200 && res.data.member) {
          member.value = res.data.member;
          memberId.value = res.data.member.id;
          answerId.value = res.data.answer_id;
          currentPage.value++;
        } else if (res.status === 200 && !res.data.member) {
          unregisteredEmail.value = true;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    watch(
      () => form.value.email,
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          invalid.value = false;
          unregisteredEmail.value = false;
        }
      }
    );

    const otp = ref(["", "", "", "", "", ""]);
    const otpRefs = ref([]);

    const isOtpComplete = computed(() =>
      otp.value.every((digit) => digit.trim().length === 1)
    );

    const handleInput = (index) => {
      otp.value[index] = otp.value[index].replace(/\D/g, "");
      const currentInput = otp.value[index];
      if (currentInput.length === 1 && index < otp.value.length - 1) {
        otpRefs.value[index + 1].focus();
      }
    };

    const handleBackspace = (index, event) => {
      if (event.key === "Backspace" && !otp.value[index] && index > 0) {
        otpRefs.value[index - 1].focus();
      }
    };

    const checkVerificationCode = async () => {
      if (isOtpComplete.value) {
        const verificationCode = otp.value.join("");
        try {
          isLoading.value = true;
          const res = await clientInstance.post("/verify/code", {
            id: memberId.value,
            code: verificationCode,
          });

          if (res.status === 200 && !res.data.message) {
            if (res.data.completion && res.data.result) {
              assessmentCompletion.value = true;
              result.value = res.data.result;
            } else if (!res.data.completion && res.data.result) {
              assessmentCompletion.value = false;
              result.value = res.data.result;
            } else {
              result.value = null;
            }
            currentPage.value++;
          } else if (res.status === 200 && res.data.message) {
            invalidCode.value = res.data.message;
          }
        } catch (err) {
          console.error("Error verifying OTP:", err);
        } finally {
          isLoading.value = false;
        }
      }
    };

    watch(currentPage, async (newPage) => {
      if (newPage === 2) {
        await nextTick();
        otpRefs.value[0]?.focus();
      }
    });

    const success = ref(null);
    const error = ref(null);

    const getVerificationCodeAgain = async () => {
      try {
        const res = await clientInstance.get(
          `/create/code?id=${memberId.value}`
        );

        if (res.status === 200 && res.data.message) {
          otp.value = ["", "", "", "", "", ""];
          success.value = "Баталгаажуулах код илгээгдлээ.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Баталгаажуулах код илгээхэд алдаа гарлаа.";
      }
    };

    const closeAlert = () => {
      success.value = null;
    };

    watch(
      otp,
      () => {
        if (invalidCode.value) {
          invalidCode.value = null;
        }
      },
      { deep: true }
    );

    return {
      form,
      invalid,
      checkEmail,
      currentPage,
      unregisteredEmail,
      otp,
      otpRefs,
      isOtpComplete,
      handleInput,
      handleBackspace,
      checkVerificationCode,
      invalidCode,
      getVerificationCodeAgain,
      success,
      error,
      closeAlert,
      isLoading,
      memberId,
      result,
      member,
      answerId,
      assessmentCompletion,
    };
  },
};
</script>
