<template>
  <main v-if="recentNews && !isLoading" class="tracking-wide font-zona">
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :autoplay="{
        delay: 5000,
        disableOnInteraction: true,
        pauseOnMouseEnter: true,
      }"
      :pagination="{ clickable: true }"
      class="mySwiper"
    >
      <swiper-slide v-for="(slide, index) in banners" :key="index">
        <img :src="slide.image" alt="image" class="w-full object-fit" />
      </swiper-slide>
    </swiper>

    <!-- NEWS -->
    <section class="flex justify-center my-10">
      <div
        class="flex max-md:flex-col w-full min-[1200px]:w-4/5 max-[1199px]:px-5 gap-5 lg:gap-10"
      >
        <swiper
          :modules="modules"
          :slides-per-view="1"
          :navigation="true"
          :loop="true"
          class="customSwiper hidden md:block w-3/5"
        >
          <swiper-slide v-for="(slide, index) in resource_slide" :key="index">
            <img :src="slide" alt="image" class="w-full object-fit" />
          </swiper-slide>
        </swiper>

        <side-container
          :title="'Шинэ мэдээ'"
          :title_en="'Recent News'"
          class="w-full md:w-2/5"
        >
          <ul>
            <li v-for="(news, index) in recentNews" :key="index">
              <router-link
                :to="`/mm/detail/${news.id}`"
                class="flex gap-3 py-5 border-b border-blue-100"
              >
                <img
                  :src="news.image"
                  :alt="news.id"
                  class="w-[6rem] object-cover object-center"
                />
                <div>
                  <span class="line-clamp-2 text-sm hover:underline">{{
                    lang === "mn" ? news.title : news.title_en
                  }}</span>
                  <div
                    class="flex items-center gap-2 text-[#69a1bb] text-xs mt-2"
                  >
                    <font-awesome-icon icon="fa-solid fa-calendar-days" />
                    {{ news.created_at }}
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </side-container>
      </div>
    </section>

    <!-- PROJECTS -->
    <section class="flex justify-center items-center my-20">
      <div
        class="w-full min-[1200px]:w-4/5 max-[1199px]:p-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10"
      >
        <MMProjects />
      </div>
    </section>
  </main>

  <OverlayLoading v-else />
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css/bundle";

import SideContainer from "../../components/MiningMongolia/SideContainer.vue";
import MMProjects from "@/containers/MiningMongolia/MMProjects.vue";
import OverlayLoading from "@/components/ui/OverlayLoading.vue";
import clientInstance from "@/lib/clientInstance";

export default {
  name: "MMHomePage",
  components: {
    SideContainer,
    MMProjects,
    Swiper,
    SwiperSlide,
    OverlayLoading,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);
    const isLoading = ref(false);
    const recentNews = computed(() => store.getters["client/getRecentNews"]);
    const banners = ref([]);

    onMounted(async () => {
      try {
        isLoading.value = true;
        await store.dispatch("client/fetchRecentNews", lang.value);
        const res = await clientInstance.get("/get/banner");
        banners.value = res.data.banner;
      } catch (error) {
        return error;
      } finally {
        isLoading.value = false;
      }
    });

    const resource_slide = [
      require("../../assets/images/miningMongolia/home-resource-slide/resource-1.png"),
      require("../../assets/images/miningMongolia/home-resource-slide/resource-2.png"),
      require("../../assets/images/miningMongolia/home-resource-slide/resource-3.png"),
    ];

    return {
      lang,
      isLoading,
      recentNews,
      resource_slide,
      banners,
      modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
    };
  },
};
</script>

<style scoped>
.mySwiper {
  --swiper-pagination-color: #537d23;
  --swiper-pagination-bullet-size: 10px;
  --swiper-pagination-bullet-inactive-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.4;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 6px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-button-prev: #537d23;
}

.customSwiper {
  position: relative;
}

.customSwiper :deep(.swiper-button-next),
.customSwiper :deep(.swiper-button-prev) {
  background-color: white;
  opacity: 50%;
  color: #537d23;
  width: 40px;
  height: 40px;
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.customSwiper:hover :deep(.swiper-button-next),
.customSwiper:hover :deep(.swiper-button-prev) {
  display: flex;
}

.customSwiper :deep(.swiper-button-next::after),
.customSwiper :deep(.swiper-button-prev::after) {
  font-size: 10px;
}

.customSwiper :deep(.swiper-button-next:hover),
.customSwiper :deep(.swiper-button-prev:hover) {
  background-color: #537d23;
  color: white;
  opacity: 100%;
}
</style>
