<template>
  <div
    class="flex justify-center items-center bg-gray-100 h-[2rem] md:h-[3rem] text-xs md:text-sm font-zona"
  >
    <div
      class="w-full min-[1200px]:w-4/5 whitespace-nowrap max-[1200px]:mx-3 overflow-hidden"
    >
      <div class="ticker" @mouseenter="pauseTicker" @mouseleave="resumeTicker">
        <div class="ticker__item" :class="{ 'ticker--paused': isPaused }">
          <span class="font-bold text-blue-500 uppercase mr-5"
            >Зарлагдсан арилжаа</span
          >
          <span
            v-for="data in noticeData"
            :key="data.id"
            class="mr-14 inline-block"
          >
            {{ data.sell_date }}-нд {{ data.seller_name }}-ийн
            {{ data.quantity }} багц {{ data.type }}-ний арилжаа зарлагдлаа.
            <a
              :href="data.own_url ? data.own_url : data.other"
              target="_blank"
              class="text-blue-500"
            >
              Дэлгэрэнгүй
            </a>
          </span>
          <span class="font-bold text-blue-500 uppercase mr-5 ml-20"
            >Арилжааны мэдээлэл</span
          >
          <span
            v-for="data in tradeData"
            :key="data.id"
            class="mr-14 inline-block"
          >
            {{ data.trade_date }}-ны {{ data.trade_time }} цагт
            {{ data.seller_name }}-ийн {{ data.mass }} тонн
            {{ data.product_category }}-ний арилжаа боллоо.
            <a
              :href="data.own_url ? data.own_url : data.url"
              target="_blank"
              class="text-blue-500"
            >
              Дэлгэрэнгүй
            </a>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { onMounted, ref } from "vue";

export default {
  name: "MMMSEData",
  setup() {
    const tradeData = ref([]);
    const noticeData = ref([]);

    const fetchMiningTradeData = async () => {
      try {
        const res = await clientInstance.get("/get/recent-trade");
        if (res.status === 200) {
          tradeData.value = res.data.trade;
        }
        const noticeRes = await clientInstance.get("/get/recent-notice");
        if (noticeRes.status === 200) {
          noticeData.value = noticeRes.data.notice;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      await fetchMiningTradeData();
    });

    const isPaused = ref(false);
    const pauseTicker = () => {
      isPaused.value = true;
    };
    const resumeTicker = () => {
      isPaused.value = false;
    };

    return {
      tradeData,
      noticeData,
      isPaused,
      pauseTicker,
      resumeTicker,
    };
  },
};
</script>

<style scoped>
@keyframes ticker {
  0% {
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
}

.ticker__item {
  display: inline-block;
  animation: ticker 260s linear infinite;
}

.ticker--paused {
  animation-play-state: paused;
}
</style>
