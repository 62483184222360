<template>
  <div class="flex justify-center items-center font-zona">
    <main class="w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-10 min-h-[50vh]">
      <div class="flex max-[550px]:flex-col max-sm:text-sm gap-5 mb-10">
        <button
          @click="selectCategory('schedule')"
          class="text-center p-1"
          :class="
            selectedCategory === 'schedule'
              ? 'text-blue-500 font-bold border-b border-blue-500'
              : 'text-gray-400 hover:text-blue-500'
          "
        >
          {{
            lang === "mn" ? "Зарлагдсан арилжааны хуваарь" : "Auction Schedule"
          }}
        </button>
        <button
          @click="selectCategory('report')"
          class="text-center p-1"
          :class="
            selectedCategory === 'report'
              ? 'text-blue-500 font-bold border-b border-blue-500'
              : 'text-gray-400 hover:text-blue-500'
          "
        >
          {{
            lang === "mn"
              ? "Уул уурхайн бүтээгдэхүүний арилжааны мэдээлэл"
              : "Mineral Commodities Trading"
          }}
        </button>
      </div>

      <MMNoticeData v-if="selectedCategory === 'schedule'" />
      <MMTradeData v-else-if="selectedCategory === 'report'" />
    </main>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import MMTradeData from "./MMTradeData.vue";
import MMNoticeData from "./MMNoticeData.vue";

export default {
  name: "MMStockPage",
  components: { MMTradeData, MMNoticeData },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedCategory = ref("schedule");
    const selectCategory = (item) => {
      selectedCategory.value = item;
    };

    return {
      lang,
      selectedCategory,
      selectCategory,
    };
  },
};
</script>
