<template>
  <div class="flex justify-center font-zona">
    <main class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 py-10">
      <div class="flex flex-col space-y-1">
        <button class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400">
          {{ lang === "mn" ? "Зөвлөлийн гишүүд" : "Board members" }}
        </button>
        <button class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400">
          {{ lang === "mn" ? "Үндсэн гишүүд" : "Members" }}
        </button>
        <button class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400">
          {{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
        </button>
      </div>

      <section id="1" class="md:flex gap-10 mt-10">
        <div class="md:w-1/3">
          <h1 class="font-bold uppercase text-lg sm:text-xl">
            {{ lang === "mn" ? "Зөвлөлийн гишүүд" : "Board members" }}
          </h1>
          <p class="text-justify">
            {{
              lang === "mn"
                ? "2022 оны Монголын уул уурхайн үндэсний ассоциацийн бүх гишүүдийн чуулганаар тус байгууллагын дүрмийн дагуу гишүүн 11 байгууллагыг зөвлөлийн гишүүнээр сонгосон."
                : "The Association Board has the right and responsibility to implement the management of MNMA. 11 members of the Board are elected by the general assembly for a three-year term. The Board members are the main members of the association."
            }}
          </p>
        </div>

        <div
          class="md:w-2/3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 justify-center items-center gap-5 md:gap-x-10 mt-5"
        >
          <a
            v-for="(logo, ImIndex) in boardMembers"
            :key="ImIndex"
            :href="logo.link"
            target="_blank"
            class="hover:scale-105"
            v-motion-fade-visible-once
          >
            <img :src="logo.logo" :alt="ImIndex" />
          </a>
        </div>
      </section>

      <section id="2" class="md:flex gap-10 mt-10">
        <div class="md:w-1/3">
          <h1 class="font-bold uppercase text-lg sm:text-xl">
            {{ lang === "mn" ? "Үндсэн гишүүд" : "Members" }}
          </h1>
          <p class="text-justify">
            {{
              lang === "mn"
                ? "Эрдэс баялгийн салбарт үйл ажиллагаа явуулдаг олборлолт, хайгуул, оператор, ханган нийлүүлэгч аж ахуй нэгжүүд"
                : "An enterprise that provides products and services in the field of mineral resources and will participate in regular and special meetings of the association with the right to vote. Prime members must sign an agreement to confirm their membership and implement the 'Responsible Mining' program."
            }}
          </p>
        </div>

        <div
          class="md:w-2/3 grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 justify-center items-center gap-5 md:gap-x-10 mt-5"
        >
          <a
            v-for="(logo, ImIndex) in mainMembers"
            :key="ImIndex"
            :href="logo.link"
            target="_blank"
            class="hover:scale-105"
            v-motion-fade-visible-once
          >
            <img :src="logo.logo" :alt="ImIndex" />
          </a>
        </div>
      </section>

      <section id="3">
        <h1 class="font-bold uppercase text-lg sm:text-xl mt-10">
          {{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
        </h1>
        <h4 class="text-green font-bold text-lg">
          {{
            lang === "mn"
              ? "БИДЭНТЭЙ НЭГДЭЖ ХАМТДАА ҮНЭ ЦЭН БҮТЭЭЦГЭЕ"
              : "JOIN US AND CREATE A VALUE TOGETHER"
          }}
        </h4>

        <ul
          class="list-disc grid grid-cols-1 md:grid-cols-2 gap-x-10 gap-y-5 mt-5"
        >
          <li v-for="(critic, index) in critics" :key="index" class="ml-3">
            <h4 class="text-green">
              {{ lang === "mn" ? critic.title : critic.title_en }}
            </h4>
            <p>{{ lang === "mn" ? critic.text : critic.text_en }}</p>
          </li>
        </ul>

        <div
          class="my-10 border p-5"
          :class="
            success
              ? 'border-[#4BB543] shadow shadow-[#4BB543]'
              : 'border-slate-300'
          "
        >
          <h1
            class="font-bold text-center text-xl md:tracking-widest uppercase mb-1"
          >
            {{ lang === "mn" ? "Хүсэлт илгээх" : "Send a Request" }}
          </h1>
          <p class="max-md:text-sm text-center font-light text-gray-700 mb-10">
            {{
              lang === "mn"
                ? "Та доорх талбараар дамжуулж Ассоциацийн ажлын албад хүсэлт гаргах боломжтой."
                : "You can submit a request to the MNMA Office through the fields below."
            }}
          </p>

          <div class="flex max-sm:flex-col gap-5 sm:gap-10">
            <div class="w-full sm:w-1/2 space-y-2 text-slate-500">
              <div
                class="flex items-center border border-slate-300 py-2 px-6 placeholder:max-sm:text-xs"
              >
                <input
                  name="name"
                  id="name"
                  type="text"
                  :placeholder="lang === 'mn' ? 'Овог нэр' : 'Company name'"
                  class="w-full max-sm:placeholder:text-sm"
                  v-model="membershipForm.name"
                />
                <font-awesome-icon icon="fa-regular fa-user" class="ml-6" />
              </div>

              <div class="flex items-center border border-slate-300 py-2 px-6">
                <input
                  name="email"
                  id="email"
                  type="email"
                  :placeholder="
                    lang === 'mn' ? 'И-мэйл хаяг' : 'E-mail address'
                  "
                  class="w-full max-sm:placeholder:text-sm"
                  v-model="membershipForm.email"
                />
                <font-awesome-icon icon="fa-regular fa-envelope" class="ml-6" />
              </div>

              <div
                class="flex items-center border border-slate-300 py-2 px-6 max-sm:placeholder:text-xs"
              >
                <input
                  name="phone_number"
                  id="phone_number"
                  type="tel"
                  :placeholder="
                    lang === 'mn' ? 'Утасны дугаар' : 'Phone Number'
                  "
                  class="w-full max-sm:placeholder:text-sm"
                  v-model="membershipForm.phone_number"
                />
                <img
                  src="@/assets/svg/phone-line-icon.svg"
                  class="h-[1rem] w-[1rem] ml-6"
                />
              </div>

              <div
                class="flex items-center border border-slate-300 py-2 px-6 max-sm:placeholder:text-xs"
              >
                <input
                  name="business_type"
                  id="business_type"
                  type="text"
                  :placeholder="
                    lang === 'mn' ? 'Бизнесийн төрөл' : 'Type of Business'
                  "
                  class="w-full max-sm:placeholder:text-sm"
                  v-model="membershipForm.business_type"
                />
                <font-awesome-icon
                  icon="fa-regular fa-clipboard"
                  class="ml-6"
                />
              </div>
            </div>

            <div
              class="flex flex-col gap-3 justify-between max-sm:text-sm w-full sm:w-1/2"
            >
              <div class="space-y-2">
                <span>{{
                  lang === "mn"
                    ? "Бид танд яаж туслах вэ?"
                    : "How can we help you?"
                }}</span>
                <div class="flex items-center gap-3 ml-3">
                  <input
                    name="member_request"
                    id="member_request"
                    type="checkbox"
                    v-model="membershipForm.member_request"
                  />{{ lang === "mn" ? "Гишүүнээр элсэх" : "Become a member" }}
                </div>
                <div class="flex items-center gap-3 ml-3">
                  <input
                    name="codex_request"
                    id="codex_request"
                    type="checkbox"
                    v-model="membershipForm.codex_request"
                  />{{
                    lang === "mn"
                      ? "ХУУ кодекс нэгдэх"
                      : "Join the Responsible Mining CODEX"
                  }}
                </div>
                <div class="flex items-center gap-3 ml-3">
                  <input
                    name="meeting_request"
                    id="meeting_request"
                    type="checkbox"
                    v-model="membershipForm.meeting_request"
                  />{{
                    lang === "mn"
                      ? "Уулзалтын цаг товлох"
                      : "Make an appointment"
                  }}
                </div>
              </div>

              <button
                @click="sendRequest()"
                type="submit"
                class="text-white p-2 w-[10rem] hover:before:bg-lightGreen relative overflow-hidden bg-green transition-all before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0 before:h-full before:w-0 before:bg-lightGreen before:transition-all before:duration-500 hover:before:left-0 hover:before:w-full"
              >
                <span class="relative z-10">
                  {{ lang === "mn" ? "Илгээх" : "Send" }}</span
                >
              </button>
            </div>
          </div>

          <h6
            v-if="success"
            class="text-center text-lg text-[#4BB543] uppercase mt-5"
          >
            {{
              lang === "mn"
                ? "Хүсэлт амжилттай илгээгдлээ"
                : "Request was sent successfully!"
            }}
          </h6>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "AssociationMembers",
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const critics = ref([
      {
        title: "Хариуцлагатай уул уурхайг хөгжүүлэх",
        title_en: "PROMOTE RESPONSIBLE MINING",
        text: "“Хариуцлагатай уул уурхайн кодекс”-ийг хэрэгжүүлж тогтвортой уул уурхайг бий болгох",
        text_en:
          "Implement a “Responsible mining codex” that helps the development of sustainable mining operation",
      },
      {
        title: "Хууль эрх зүйн оновчтой зохицуулалт",
        title_en: "REGULATORY EFFECTIVENESS",
        text: "Уул уурхайн салбарын хууль эрхзүйн орчны шинэчлэлд нэгдсэн санал бодлоо тусгах",
        text_en:
          "Build up a united voice for policy advocacy for the national mining sector",
      },
      {
        title: "Уул уурхайн тогтвортой үйл ажиллагаа",
        title_en: "SUSTAINABLE MINING",
        text: "Уул уурхайн салбарт тогтвортой өсөлтийг бий болгох",
        text_en: "Create sustainable growth in the national mining sector",
      },
      {
        title: "Өрсөлдөх чадварыг дээшлүүлэх",
        title_en: "INCREASE COMPETITIVENESS",
        text: "Олон улсын эрдэс баялгийн зах зээлд өрсөлдөх чадварыг нэмэгдүүлэх",
        text_en:
          "Increase competitiveness of the national mining sector in the international mineral market",
      },
      {
        title: "Гадаад дотоодын хөрөнгө оруулалтыг сайжруулах",
        title_en: "PROMOTE FOREIGN & DOMESTIC INVESTMENT",
        text: "Хөрөнгө оруулалтын таатай орчныг бий болгох",
        text_en: "Create an attractive investment environment",
      },
      {
        title: "Гадаад дотоодын хамтын ажиллагааг хөгжүүлэх",
        title_en: "DEVELOP INTERNATIONAL AND DOMESTIC COLLABORATION",
        text: "Уул уурхайн салбарт гадаад, дотоодын хамтын ажиллагааг хөгжүүлж шинэ технологийг нэвтрүүлэх",
        text_en:
          "Promote domestic & international collaboration & adopt new technologies",
      },
    ]);

    const boardMembers = ref([]);
    const mainMembers = ref([]);
    const isLoading = ref(false);

    const categorizeLogos = (logos) => {
      logos.forEach((image) => {
        if (image.board_member) {
          boardMembers.value.push({
            logo: image.logo,
            link: image.link,
          });
        }
        if (image.main_member) {
          mainMembers.value.push({
            logo: image.logo,
            link: image.link,
          });
        }
      });
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/get/member-logo");
        if (res.status === 200) {
          categorizeLogos(res.data.logos);
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    });

    const isRequestLoading = ref(false);
    const success = ref("");
    const membershipForm = ref({
      name: "",
      email: "",
      phone_number: "",
      business_type: "",
      member_request: false,
      codex_request: false,
      meeting_request: false,
    });

    const resetForm = () => {
      membershipForm.value = {
        name: "",
        email: "",
        phone_number: "",
        business_type: "",
        member_request: false,
        codex_request: false,
        meeting_request: false,
      };
    };

    const isValid = () => {
      if (!membershipForm.value.name) {
        return alert("Овог нэрээ оруулна уу");
      }
      if (!membershipForm.value.email) {
        return alert("Имэйл хаягаа оруулна уу");
      }
      if (!membershipForm.value.phone_number) {
        return alert("Утасны дугаараа оруулна уу");
      }
      if (!membershipForm.value.business_type) {
        return alert("Бизнесийн төрлөө оруулна уу");
      }
      if (
        !membershipForm.value.member_request &&
        !membershipForm.value.codex_request &&
        !membershipForm.value.meeting_request
      ) {
        return alert("Хүсэлтийн зорилгийг бөглөнө үү");
      }
      return true;
    };

    const sendRequest = async () => {
      if (!isValid()) return;

      try {
        isRequestLoading.value = true;
        const res = await clientInstance.post(
          "/insert/office-request",
          membershipForm.value
        );
        if (res.status === 200) {
          success.value = "Хүсэлт амжилттай илгээгдлээ.";
          resetForm();

          setTimeout(() => {
            success.value = null;
          }, 10000);
        }
      } catch (err) {
        return err;
      } finally {
        isRequestLoading.value = false;
      }
    };

    return {
      lang,
      critics,
      isLoading,
      boardMembers,
      mainMembers,
      isRequestLoading,
      success,
      membershipForm,
      resetForm,
      sendRequest,
    };
  },
};
</script>
