<template>
  <div v-if="currentPage <= totalPages" class="mt-5 font-zona">
    <h1 class="uppercase font-semibold text-blue-500 text-xl text-center mb-3">
      Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
    </h1>

    <table v-if="currentItem" class="w-full">
      <thead class="bg-slate-300 text-black">
        <tr>
          <th class="border border-slate-300">
            {{ currentItem.mainIndex }}
          </th>
          <th class="border border-slate-300 uppercase">
            {{ currentItem.mainTitle }}
          </th>
          <th class="border border-slate-300">Тийм (1)</th>
          <th class="border border-slate-300">Үгүй (0)</th>
          <th class="border border-slate-300">Нотлох эх үүсвэр байгаа эсэх</th>
          <th class="border border-slate-300">Тайлбар</th>
        </tr>
      </thead>

      <tbody>
        <template
          v-for="(question, qIndex) in currentItem.questions"
          :key="qIndex"
        >
          <!-- Parent Question -->
          <tr class="text-sm bg-slate-100">
            <td class="p-2 border border-slate-300">
              {{ question.cid }}
            </td>
            <td class="p-2 border border-slate-300">
              {{ question.question }}
            </td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
            <td class="border border-slate-300"></td>
          </tr>

          <!-- Child Questions -->
          <tr
            v-for="(childQuestion, cIndex) in question.children"
            :key="cIndex"
            class="text-sm"
          >
            <td class="p-2 border border-slate-300">
              {{ childQuestion.cid }}
            </td>
            <td
              class="p-2 border border-slate-300"
              :class="
                childQuestion.isUnanswered ? 'bg-red-200' : 'bg-transparent'
              "
            >
              {{ childQuestion.question }}
            </td>
            <td class="border border-slate-300">
              <div class="flex justify-center items-center">
                <input
                  type="radio"
                  :name="childQuestion.cid"
                  :value="1"
                  v-model="childQuestion.answer"
                  @change="updateAnswerStatus(childQuestion)"
                />
              </div>
            </td>
            <td class="border border-slate-300">
              <div class="flex justify-center items-center">
                <input
                  type="radio"
                  :name="childQuestion.cid"
                  :value="0"
                  v-model="childQuestion.answer"
                  @change="updateAnswerStatus(childQuestion)"
                />
              </div>
            </td>
            <td class="border border-slate-300">
              <div class="flex flex-col justify-center items-center">
                <input
                  type="checkbox"
                  :name="childQuestion.cid"
                  v-model="childQuestion.proof"
                />
                <input
                  v-if="childQuestion.proof"
                  type="file"
                  name="proof"
                  @change="handleFileUpload($event, childQuestion)"
                  class="m-2 w-32"
                />
              </div>
            </td>

            <td class="border border-slate-300">
              <textarea
                class="w-full p-2 w-32 placeholder:text-sm"
                v-model="childQuestion.comment"
                placeholder="Тайлбар"
              ></textarea>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    <p v-if="errorMessage" class="text-center text-red-500 text-xs mt-5">
      {{ errorMessage }}
    </p>

    <div
      class="w-full flex items-center mt-5"
      :class="currentPage === totalPages ? 'justify-between' : 'justify-center'"
    >
      <PaginationCartTwo
        :modelValue="currentPage"
        :totalPages="totalPages"
        @page-changed="handlePageChanged"
        @request-next-page="handleNextPageRequest"
      />

      <button
        v-if="currentPage === totalPages"
        @click="submitAnswers"
        class="bg-blue-500 p-3 text-white text-sm rounded-lg hover:scale-110"
      >
        Дуусгах
      </button>
    </div>
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      isLoading &&
      !isFinished
    "
    class="flex flex-col justify-center items-center w-full min-h-[40vh] font-zona"
  >
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      !isLoading &&
      !isFinished
    "
    class="flex max-sm:flex-col justify-center w-full gap-x-10 gap-y-10 font-zona"
  >
    <!-- ZURAG -->
    <div class="w-full sm:w-1/2">
      <div
        ref="contentToDownload"
        class="relative flex justify-center items-center overflow-hidden"
      >
        <img
          src="@/assets/images/miningMongolia/codex_poster.jpg"
          alt="poster"
          class="w-full border"
        />

        <div
          class="absolute inset-0 flex flex-col items-center w-full h-full mt-10 sm:mt-14 lg:mt-28"
        >
          <h1
            class="font-bold uppercase text-sm min-[400px]:text-base min-[500px]:text-lg min-[1100px]:text-xl text-center"
          >
            Хариуцлагатай <br />
            уул уурхайн кодекс
          </h1>

          <div v-if="logo" class="flex justify-center w-full my-3 lg:my-5">
            <img :src="logo" alt="logo" class="w-1/2 object-contain" />
          </div>
          <h6
            class="hidden min-[500px]:flex font-bold uppercase text-sm min-[400px]:text-base min-[500px]:text-lg"
          >
            өөрийн үнэлгээ
          </h6>
          <p class="text-7xl text-[#4bb543] font-bold">
            {{ `${resultPercentage}%` }}
          </p>
        </div>
      </div>

      <div class="flex gap-5">
        <button
          @click="downloadImage"
          class="flex items-center border-b mt-3 text-sm text-gray-500 px-2.5 py-1 hover:text-green hover:border-green"
        >
          <font-awesome-icon icon="fa-solid fa-download" class="mr-1" />
          Зураг татах
        </button>
        <button
          class="flex items-center border-b mt-3 text-sm text-gray-500 px-2.5 py-1 cursor-not-allowed hover:text-green hover:border-green"
        >
          <font-awesome-icon icon="fa-solid fa-download" class="mr-1" />
          Бөглөсөн үнэлгээг татах
        </button>
      </div>
    </div>

    <div class="w-full sm:w-1/2 text-sm">
      <!-- SECTION POINTS -->
      <div
        class="grid grid-cols-3 md:grid-cols-6 w-full mb-8 shadow rounded-xl"
      >
        <div class="border-r p-2">
          <p class="text-center">Нийт</p>
          <p class="font-bold text-xl text-center text-blue-500">
            {{ resultPercentage ? `${resultPercentage}%` : "-" }}
          </p>
        </div>
        <div class="border-r p-2">
          <p class="text-center">1-р хэсэг</p>
          <p class="font-bold text-xl text-center">
            {{
              savedResult?.first_section_per
                ? `${savedResult?.first_section_per}%`
                : "-"
            }}
          </p>
        </div>
        <div class="md:border-r p-2">
          <p class="text-center">2-р хэсэг</p>
          <p class="font-bold text-xl text-center">
            {{
              savedResult?.second_section_per
                ? `${savedResult?.second_section_per}%`
                : "-"
            }}
          </p>
        </div>
        <div class="border-r p-2">
          <p class="text-center">3-р хэсэг</p>
          <p class="font-bold text-xl text-center">
            {{
              savedResult?.third_section_per
                ? `${savedResult?.third_section_per}%`
                : "-"
            }}
          </p>
        </div>
        <div class="border-r p-2">
          <p class="text-center">4-р хэсэг</p>
          <p class="font-bold text-xl text-center">
            {{
              savedResult?.fourth_section_per
                ? `${savedResult?.fourth_section_per}%`
                : "-"
            }}
          </p>
        </div>
        <div class="p-2">
          <p class="text-center">5-р хэсэг</p>
          <p class="font-bold text-xl text-center">
            {{
              savedResult?.fifth_section_per
                ? `${savedResult?.fifth_section_per}%`
                : "-"
            }}
          </p>
        </div>
      </div>

      <!-- FILES -->
      <p class="flex items-center text-gray-500 mb-3 text-xs">
        <font-awesome-icon
          icon="fa-solid fa-exclamation"
          class="mr-2 text-[#FFE900] text-lg"
        />
        Хавсралт баримт бичгүүдийг доор оруулна уу. Хавсралт баримт бичгүүдийг
        илгээснээр үнэлгээ бүрэн хийгдэх болно.
      </p>
      <label for="official_document"
        >1. Албан бичиг:<span class="text-red-500 text-sm">*</span></label
      >
      <input
        id="official_document"
        name="official_document"
        type="file"
        class="p-2 border rounded w-full"
        :class="
          !notFilled || notFilled === 'form_document'
            ? 'mb-3'
            : 'mb-1 border-red-500'
        "
        @change="handleFileChange($event, 'official_document')"
      />
      <p v-if="notFilled === 'official_document'" class="text-red-500">
        Заавал бөглөнө үү!
      </p>

      <label for="form"
        >2. Маягт & тайлан:<span class="text-red-500 text-sm">*</span></label
      >
      <input
        id="form"
        name="form"
        type="file"
        class="p-2 border rounded w-full"
        :class="
          !notFilled || notFilled === 'official_document'
            ? 'mb-3'
            : 'mb-1 border-red-500'
        "
        @change="handleFileChange($event, 'form')"
      />
      <p v-if="notFilled === 'form_document'" class="text-red-500">
        Заавал бөглөнө үү!
      </p>

      <div class="flex gap-x-1 mb-5">
        <input
          id="publication"
          name="publication"
          type="checkbox"
          v-model="publication"
        /><label for="publication"
          >Олон нийтэд үр дүнг зарлахыг зөвшөөрч байна.</label
        >
      </div>

      <button
        @click="submitAttachment"
        type="submit"
        :disabled="!officialDocument || !formDocument"
        class="text-white p-2 rounded-lg bg-blue-500 enabled:hover:bg-blue-600 disabled:cursor-not-allowed"
      >
        <font-awesome-icon icon="fa-solid fa-paper-plane" class="mr-1" />
        Илгээх
      </button>
    </div>
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      isLoading &&
      isFinished
    "
    class="flex flex-col justify-center items-center w-full min-h-[40vh] font-zona"
  >
    <SpinLoading :color="'rgb(59 130 246)'" />
  </div>

  <div
    v-else-if="
      currentPage > totalPages &&
      resultPercentage !== null &&
      !isLoading &&
      isFinished
    "
    class="flex flex-col justify-center items-center w-full my-10 md:my-20 font-zona"
  >
    <SuccessAnimation />

    <h1
      class="uppercase font-semibold text-blue-500 text-xl text-center mt-5 md:mt-10"
    >
      Хариуцлагатай Уул Уурхай - Өөрийн үнэлгээ
    </h1>

    <p class="uppercase text-center text-gray-500 mb-5">
      <font-awesome-icon icon="fa-solid fa-paper-plane" class="mr-1" />Та
      үнэлгээг амжилттай илгээсэн байна<font-awesome-icon
        icon="fa-solid fa-paper-plane"
        class="ml-1"
      />
    </p>

    <router-link
      to="/mm"
      class="text-blue-500 underline text-center text-sm hover:text-blue-600"
      >Нүүр хуудас руу буцах</router-link
    >
  </div>

  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import PaginationCartTwo from "@/components/ui/PaginationCartTwo.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import SuccessAnimation from "@/components/ui/SuccessAnimation.vue";
import clientInstance from "@/lib/clientInstance";
import { computed, onMounted, ref } from "vue";
import html2canvas from "html2canvas";

export default {
  name: "MMAssessmentTable",
  components: {
    PaginationCartTwo,
    SpinLoading,
    ErrorModal,
    SuccessAnimation,
  },
  props: {
    finished: Boolean,
    result: Object,
    email: String,
    member: Object,
    memberId: Number,
    answerId: Number,
  },
  setup(props) {
    const updateAnswerStatus = (childQuestion) => {
      childQuestion.isUnanswered =
        childQuestion.answer === undefined || childQuestion.answer === null;
      if (!childQuestion.comment) {
        childQuestion.comment = "";
      }
    };

    //ASSESSMENT TEST
    const assessQuestions = ref([]);
    const currentPage = ref(1);
    const totalPages = ref(1);
    const errorMessage = ref(null);
    const isLoading = ref(false);
    const savedAnswerId = ref(null);

    const fetchAssessmentQuestions = async () => {
      try {
        const res = await clientInstance.get("/get/assessment");
        assessQuestions.value = res.data.result;
        totalPages.value = assessQuestions.value.length;
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      if (props.finished && props.result) {
        isFinished.value = true;
        resultPercentage.value = props.result.main_percentage;
        currentPage.value++;
      } else if (props.result && !props.finished) {
        resultPercentage.value = props.result.main_percentage;
        resultId.value = props.result.id;
        savedResult.value = props.result;
        currentPage.value++;
        savedAnswerId.value = props.result.answer_id || props.answerId + 1;
      } else {
        savedAnswerId.value = props.answerId + 1;
        await fetchAssessmentQuestions();
      }
    });

    const currentItem = computed(() => {
      return assessQuestions.value[currentPage.value - 1];
    });

    const handlePageChanged = (page) => {
      currentPage.value = page;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleNextPageRequest = (newPage) => {
      const currentQuestions = currentItem.value.questions;
      let allQuestionsAnswered = true;

      currentQuestions.forEach((question) => {
        question.children.forEach((child) => {
          if (child.answer === undefined || child.answer === null) {
            child.isUnanswered = true;
            allQuestionsAnswered = false;
            errorMessage.value = "Бүх асуултанд хариулна уу.";
            setTimeout(() => {
              errorMessage.value = null;
            }, 5000);
          } else {
            child.isUnanswered = false;
          }
        });
      });

      if (!allQuestionsAnswered) {
        return;
      }

      currentPage.value = newPage;
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleFileUpload = (event, childQuestion) => {
      const file = event.target.files[0];
      childQuestion.proof_file = file;
    };

    //SAVE ANSWERS
    const resultPercentage = ref(null);
    const globalTrueCount = ref(0);
    const globalFalseCount = ref(0);
    const resultId = ref(null);
    const savedResult = ref(null);
    const error = ref(null);

    const submitAnswers = async () => {
      try {
        isLoading.value = true;

        let totalQuestions = 0;
        const mainQuestionResults = [];

        for (const mainQuestion of assessQuestions.value) {
          let localTrueCount = 0;
          let localFalseCount = 0;
          let localTotalQuestions = 0;

          for (const secondLevelQuestion of mainQuestion.questions) {
            for (const thirdLevelQuestion of secondLevelQuestion.children) {
              try {
                const newAnswerId = props.answerId + 1;

                const formData = new FormData();
                formData.append("question_id", thirdLevelQuestion.id);
                formData.append("answer", thirdLevelQuestion.answer);
                formData.append("member_id", props.memberId);
                formData.append(
                  "proof",
                  thirdLevelQuestion.proof ? true : false
                );
                formData.append("comment", thirdLevelQuestion.comment);
                if (thirdLevelQuestion.proof_file) {
                  formData.append("proof_file", thirdLevelQuestion.proof_file);
                }
                formData.append("answer_id", newAnswerId);

                const res = await clientInstance.post(
                  "/insert/member-answer",
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                );

                if (res.status === 200) {
                  totalQuestions++;
                  localTotalQuestions++;

                  if (thirdLevelQuestion.answer === 1) {
                    localTrueCount++;
                    globalTrueCount.value++;
                  } else if (thirdLevelQuestion.answer === 0) {
                    localFalseCount++;
                    globalFalseCount.value++;
                  }
                } else {
                  error.value = "Алдаа гарлаа!";
                  setTimeout(() => {
                    error.value = null;
                  }, 5000);
                }
              } catch (err) {
                console.error(
                  `Error submitting answer for question_id ${thirdLevelQuestion.id}:`,
                  err
                );
              }
            }
          }

          const mainTruePercentage =
            localTotalQuestions > 0
              ? Math.round((localTrueCount / localTotalQuestions) * 100)
              : 0;

          mainQuestionResults.push({
            mainQuestionId: mainQuestion.id,
            trueCount: localTrueCount,
            falseCount: localFalseCount,
            truePercentage: mainTruePercentage,
          });
        }

        const truePercentage = Math.round(
          (globalTrueCount.value / totalQuestions) * 100
        );
        resultPercentage.value = truePercentage;

        const resultRes = await clientInstance.post("/insert/result", {
          main_percentage: truePercentage,
          first_section_per: mainQuestionResults[0]?.truePercentage || null,
          second_section_per: mainQuestionResults[1]?.truePercentage || null,
          third_section_per: mainQuestionResults[2]?.truePercentage || null,
          fourth_section_per: mainQuestionResults[3]?.truePercentage || null,
          fifth_section_per: mainQuestionResults[4]?.truePercentage || null,
          total_true: globalTrueCount.value,
          total_false: globalFalseCount.value,
          member_id: props.memberId,
          answer_id: savedAnswerId.value,
        });

        if (resultRes.status === 200) {
          resultId.value = resultRes.data.result.id;
          savedResult.value = resultRes.data.result;
          currentPage.value++;
        }
      } catch (err) {
        console.error("Error submitting answers:", err);
      } finally {
        isLoading.value = false;
      }
    };

    //ASSESSMENT ATTACHMENT
    const officialDocument = ref(null);
    const formDocument = ref(null);
    const publication = ref(true);
    const notFilled = ref(null);
    const isFinished = ref(false);

    const handleFileChange = (event, type) => {
      const file = event.target.files[0];

      if (type === "official_document") {
        officialDocument.value = file;
        if (notFilled.value === "official_document") {
          notFilled.value = null;
        }
      } else if (type === "form") {
        formDocument.value = file;
        if (notFilled.value === "form_document") {
          notFilled.value = null;
        }
      }
    };

    const submitAttachment = async () => {
      if (!officialDocument.value) {
        notFilled.value = "official_document";
        return;
      }

      if (!formDocument.value) {
        notFilled.value = "form_document";
        return;
      }

      try {
        isLoading.value = true;
        const formData = new FormData();
        formData.append("official_document", officialDocument.value);
        formData.append("form", formDocument.value);
        formData.append("publication", publication.value);
        formData.append("result_id", resultId.value);
        formData.append("answer_id", savedAnswerId.value);

        const res = await clientInstance.post("/insert/attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (res.status === 200) {
          isFinished.value = true;
        }
      } catch (err) {
        console.error("Error submitting attachment:", err);
      } finally {
        isLoading.value = false;
      }
    };

    //DOWNLOAD IMAGE
    const logo = ref(props.member.logo);
    const contentToDownload = ref(null);
    const downloadImage = () => {
      html2canvas(contentToDownload.value, {
        allowTaint: true,
        useCORS: true,
      })
        .then((canvas) => {
          const link = document.createElement("a");
          link.href = canvas.toDataURL("image/png");
          link.download = "unelgee.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error during canvas rendering: ", error);
        });
    };

    return {
      updateAnswerStatus,
      handlePageChanged,
      submitAnswers,
      handleNextPageRequest,
      totalPages,
      currentItem,
      currentPage,
      assessQuestions,
      errorMessage,
      isLoading,
      resultPercentage,
      handleFileUpload,
      submitAttachment,
      officialDocument,
      formDocument,
      publication,
      handleFileChange,
      notFilled,
      isFinished,
      error,
      contentToDownload,
      downloadImage,
      logo,
      savedResult,
    };
  },
};
</script>
