<template>
  <main class="flex flex-col items-center justify-center">
    <section
      class="relative w-full overflow-hidden text-sm text-white h-[20vh] sm:h-[80vh]"
    >
      <div class="relative w-screen h-[20vh] sm:h-[80vh]">
        <iframe
          src="https://www.youtube-nocookie.com/embed/ZtPna70Wttc?autoplay=1&mute=1&loop=1&playlist=ZtPna70Wttc&controls=0&modestbranding=1&iv_load_policy=3&rel=0&si=eSx2kT78vpo2ovxV&amp;"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
          class="absolute top-[-15%] left-[-10%] w-[120%] h-[135%] overflow-hidden"
        ></iframe>
      </div>
      <div
        class="absolute bottom-0 left-0 h-2/3 w-full bg-gradient-to-t from-[#001F3D] to-transparent"
      ></div>
      <div class="overlay absolute top-0 left-0 w-full h-full"></div>
      <div
        class="absolute bottom-0 sm:bottom-24 left-0 flex justify-center items-center w-full"
      >
        <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5">
          <h3 class="text-sm sm:text-xl lg:text-2xl mb-1 md:mb-3">
            Ulaanbaatar, Mongolia Sep 11-13, 2025
          </h3>
          <h1
            class="font-bold text-2xl md:text-4xl lg:text-7xl uppercase mb-1 md:mb-3"
          >
            Mining Week 2025
          </h1>
          <h3
            class="font-bold text-sm sm:text-base md:text-xl lg:text-3xl uppercase"
          >
            Mining Momentum in Mongolia: A progress in responsible mining
          </h3>
        </div>
      </div>
    </section>

    <MWBackground>
      <div
        class="flex flex-col items-center min-h-screen max-[1199px]:px-5 text-white z-10"
      >
        <img
          v-motion-fade-visible-once
          :enter="{
            transition: {
              delay: 1000,
            },
          }"
          src="@/assets/images/miningWeek2025/banner.jpg"
          alt="logo"
          class="w-full mt-10 md:mt-20"
        />

        <section
          class="flex flex-col items-center w-full min-[1200px]:w-4/5 gap-10 my-10 md:my-20"
        >
          <!-- <img
            v-motion-slide-visible-once-bottom
            src="@/assets/images/miningWeek2025/miningweek minepro 2025 logo.png"
            alt="logo"
            class="w-full md:w-3/4 lg:w-3/5 object-contain"
          /> -->

          <div
            class="grid grid-cols-2 min-[400px]:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 max-lg:gap-5 w-full md:my-10"
          >
            <div
              v-for="(stat, index) in statistics"
              :key="index"
              class="flex flex-col items-center gap-2 font-bold"
            >
              <span class="text-2xl md:text-4xl text-center">{{
                stat.number
              }}</span>
              <span class="uppercase text-base md:text-xl text-center">{{
                lang === "mn" ? stat.title : stat.title_en
              }}</span>
            </div>
          </div>

          <p
            v-if="lang === 'mn'"
            class="font-bold text-center uppercase max-md:text-sm"
          >
            Таныг Mining Week & MinePro 2025 <br />
            олон улсын уул уурхайн чуулган, үзэсгэлэнд урьж байна
          </p>

          <p v-else class="font-bold text-center uppercase max-md:text-sm">
            We are pleased to invite you to The Mongolian <br />
            Mining Week & MinePro 2025 conference & exhibition <br />
            <br />
            See you in Mongolia
          </p>

          <router-link
            to="/mw/register"
            class="bg-[#009269] w-1/2 sm:w-1/3 p-2 uppercase text-center hover:bg-[#085A43]"
          >
            {{ lang === "mn" ? "Бүртгүүлэх" : "Register" }}
          </router-link>
        </section>

        <section
          class="flex flex-col items-center w-full min-[1200px]:w-4/5 my-40"
        >
          <h2>{{ lang === "mn" ? "Зохион байгуулагч" : "Organiser" }}</h2>
          <div
            v-motion-fade-visible-once
            class="flex max-md:flex-col justify-center items-center gap-5 md:gap-10 min-[1100px]:gap-16 mt-4"
          >
            <img
              src="../../../assets/images/miningWeek2024/sponsors/MIMR.png"
              alt="logo-1"
              class="h-[4.5rem] object-contain"
            />
            <img
              src="../../../assets/images/miningWeek2024/sponsors/MRPAM.png"
              alt="logo-2"
              class="h-20 object-contain"
            />
            <img
              src="../../../assets/images/miningWeek/spons-logo-2.png"
              alt="logo-3"
              class="h-20 object-contain"
            />
          </div>
        </section>
      </div>
    </MWBackground>
  </main>
</template>

<script>
import MWBackground from "@/components/MiningWeek/MW2024/MWBackground";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "MWHomePage",
  components: {
    MWBackground,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const statistics = ref([
      {
        title: "зочид",
        title_en: "visitors",
        number: "30,000+",
      },
      {
        title: "улс",
        title_en: "countries & regions",
        number: "20+",
      },
      {
        title: "оролцогч",
        title_en: "exhibitors",
        number: "350+",
      },
      {
        title: "илтгэгч",
        title_en: "speakers",
        number: "123+",
      },
      {
        title: "хурал",
        title_en: "conferences in one",
        number: "12",
      },
    ]);

    return {
      lang,
      statistics,
    };
  },
};
</script>

<style scoped>
.overlay {
  background-image: url("@/assets/images/miningWeek/diagonalstripe-black.png");
  opacity: 0.6;
}
</style>
