<template>
  <main class="flex justify-center w-full font-zona">
    <div
      class="w-full min-[1200px]:w-4/5 max-[1200px]:mx-3 max-sm:p-3 sm:py-10"
    >
      <div class="sm:flex gap-5 sm:gap-10">
        <div class="space-y-1">
          <button
            class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400"
          >
            {{ lang === "mn" ? "Үндсэн ажлын хэсгүүд" : "Main working group" }}
          </button>
          <button
            class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400"
          >
            {{ lang === "mn" ? "Төсөл хөтөлбөр" : "Projects" }}
          </button>
          <button
            class="bg-gray-300 w-[15rem] p-2 text-white hover:bg-gray-400"
          >
            {{ lang === "mn" ? "Хууль эрхзүй" : "Legal" }}
          </button>
        </div>
        <div>
          <h1 class="font-bold uppercase text-xl sm:text-2xl">
            {{ lang === "mn" ? "Үйл ажиллагаа" : "Activities" }}
          </h1>
          <p class="text-justify">
            {{
              lang === "mn"
                ? "Монголын Уул Уурхайн Үндэсний Ассоциаци нь эрдэс баялгийн салбарт үйлдвэрлэл, үйлчилгээ явуулдаг аж ахуйн нэгж, байгууллагуудыг гишүүнчлэлээр баталгаажуулсан, гишүүддээ үйлчилдэг, хараат бус үйл ажиллагаа явуулдаг, ашгийн төлөө бус ТББ юм."
                : "The Mongolian National Mining Association is an independent non-profit NGO that serves its members and provides membership to enterprises and organizations that provide production and services in the mineral resources sector."
            }}
          </p>
        </div>
      </div>

      <section>
        <h1 class="font-bold uppercase text-lg sm:text-xl mt-10">
          {{ lang === "mn" ? "АССОЦИАЦИЙН ХОРООД" : "ASSOCIATION COMMITTEES" }}
        </h1>
        <p class="text-justify">
          {{
            lang === "mn"
              ? "Монголын уул уурхайн үндэсний ассоциацийн дүрмийн дагуу Гүйцэтгэх захирлын дэргэдэх орон тооны бус хороод үйл ажиллагаа явуулдаг."
              : "Committees under Execution Director operate according to the rules of the Mongolian National Mining Association."
          }}
        </p>

        <div class="grid grid-cols-1 md:grid-cols-2 gap-10 mt-10 text-sm">
          <div v-for="(committee, index) in committees" :key="index">
            <h4 class="text-green font-bold text-lg">
              {{ lang === "mn" ? committee.title : committee.title_en }}
            </h4>
            <p class="text-justify">
              {{ lang === "mn" ? committee.text : committee.text_en }}
            </p>

            <div v-if="lang === 'mn' ? committee.list : committee.list_en">
              <ul v-if="lang === 'mn'" class="list-disc pl-10">
                <li v-for="(li, lIndex) in committee.list" :key="lIndex">
                  {{ li }}
                </li>
              </ul>
              <ul v-else class="list-disc pl-10">
                <li v-for="(li, lIndex) in committee.list_en" :key="lIndex">
                  {{ li }}
                </li>
              </ul>
            </div>

            <div
              class="grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4 justify-center items-center gap-5 mt-5"
            >
              <a
                v-for="(logo, ImIndex) in committee.logos"
                :key="ImIndex"
                :href="logo.link"
                target="_blank"
                class="hover:scale-105"
                v-motion-fade-visible-once
              >
                <img :src="logo.logo" :alt="ImIndex" />
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="1">
        <h1 class="font-bold uppercase text-lg sm:text-xl mt-20">
          {{ lang === "mn" ? "Үндсэн ажлын хэсгүүд" : "Main working groups" }}
        </h1>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5 md:gap-10 mt-5 text-sm"
        >
          <div v-for="(group, index) in mainWorkingGroups" :key="index">
            <img :src="group.logo" alt="logo" class="h-16 object-contain" />
            <div
              v-if="lang === 'mn' ? group.list : group.list_en"
              class="mt-1 ml-3"
            >
              <ul v-if="lang === 'mn'" class="list-disc">
                <li v-for="(li, lIndex) in group.list" :key="lIndex">
                  {{ li }}
                </li>
              </ul>
              <ul v-else class="list-disc">
                <li v-for="(li, lIndex) in group.list_en" :key="lIndex">
                  {{ li }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id="2">
        <h1 class="font-bold uppercase text-lg sm:text-xl mt-10">
          {{ lang === "mn" ? "Төсөл хөтөлбөр" : "Projects" }}
        </h1>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 mt-5">
          <MMProjects />
        </div>
      </section>

      <section id="3">
        <h1 class="font-bold uppercase text-lg sm:text-xl mt-10">
          {{ lang === "mn" ? "Хууль эрхзүй" : "Legal" }}
        </h1>
      </section>
    </div>
  </main>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import MMProjects from "@/containers/MiningMongolia/MMProjects.vue";
import clientInstance from "@/lib/clientInstance";

export default {
  name: "AssociationActivity",
  components: {
    MMProjects,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const committees = ref([
      {
        title: "ОЛОН НИЙТТЭЙ ХАРИЛЦАХ ХОРОО",
        title_en: "PUBLIC RELATION COMMITTEE",
        text: "Тус хороо нь гишүүн байгууллагуудын олон нийттэй харилцах албаны мэргэжилтнүүдээс бүрдсэн бөгөөд дараах 3 чиглэлд үйл ажиллагаа явуулдаг.",
        text_en:
          "The committee consists of experts from public relations departments of member organizations and operates in the following 3 areas.",
        list: [
          "Уул уурхайн салбарын үнэн, бодит мэдээллийг олон нийтэд түгээх",
          "Оролцогч талуудын мэдлэг, мэдээллийг нэмэгдүүлэх",
          "Хариуцлагатай уул уурхайг сурталчлан таниулах",
        ],
        list_en: [
          "Dissemination of the truth and factual information of the mining industry to the public.",
          "Increase awareness and information among stakeholders",
          "Promote Responsible Mining",
        ],
        logos: [],
      },
      {
        title: "БАЙГАЛЬ ОРЧНЫ ХОРОО",
        title_en: "ENVIRONMENT COMMITTEE",
        text: "Хорооны зорилго нь уул уурхайн төслүүдэд тулгардаг байгаль орчны асуудалтай холбоотой дараах асуудлыг чиглэн ажилладаг.",
        text_en:
          "The purpose of the committee is to address the following issues related to environmental issues faced by mining projects.",
        list: [
          "Байгаль орчны нөлөөллийн нарийвчилсан үнэлгээ",
          "Байгаль орчны менежментийн төлөвлөгөө",
          "Химийн бодисын лиценз",
          "Усны зөвшөөрлүүд",
          "Газрын зөвшөөрөл",
          "Түгээмэл тархацтай ашигт малтмал",
          "Нөхөн сэргээлт/ дүйцүүлэн хамгаалах",
          "Хог хаягдал",
          "Мэргэжлийн зөвлөл",
        ],
        list_en: [
          "Detailed environmental impact assessment",
          "Environmental Management Plan",
          "Chemical license",
          "Water permits",
          "Land permit",
          "Minerals of widespread distribution",
          "Rehabilitation / resilience",
          "Waste Management",
          "Professional council",
        ],
        logos: [],
      },
      {
        title: "ХУУЛЬ ЭРХЗҮЙН ХОРОО",
        title_en: "LEGAL COMMITTEE",
        text: "Тус хороо нь салбарт хамааралтай хууль, дүрэм, журам мөн нэмэлт өөрчлөлтүүд дээр санал оруулах, Засгийн газраас томилсон үндэсний хороо, ажлын хэсэгт хувийн хэвшлийг төлөөлж санал боловсруулах, Уул уурхайн салбарын хууль эрх зүйн орчны шинэчлэлд нэгдсэн санал бодлоо тусгах зорилготой хороо юм.",
        text_en:
          "The purpose of the committee is to make suggestions on the laws, rules and regulations and amendments related to the sector, to formulate proposals on behalf of the private sector in national committees and working groups appointed by the government, and to reflect common opinions on the reform of the legal framework of the mining sector.",
        logos: [],
      },
      {
        title: "ТЭЭВЭР ЛОЖИСТИКИЙН ХОРОО",
        title_en: "TRANSPORT, LOGISTICS COMMITTEE",
        text: "Аж ахуй нэгжүүдийн тээвэрлэлт ложистиктой холбоотой асуудал эрхэлсэн тээвэр ложистикийн хороо үйл ажиллагаа явуулж байна.",
        text_en:
          "The transport logistics committee in charge of issues related to transportation and logistics of enterprises is operating.",
        logos: [],
      },
    ]);

    const mainWorkingGroups = ref([
      {
        logo: require("@/assets/images/miningMongolia/activity/17052849391009091801.png"),
        list: [
          "Аж үйлдвэржилтийн үндэсний хороо",
          "Зөвшөөрлийн зөвлөл",
          "Өмнийн говийн бүсийн хөгжлийн зөвлөл",
          "Мэргэжлийн боловсрол, сургалтын үндэсний зөвлөл",
        ],
        list_en: [
          "National Industrialization Committee",
          "Permitting Council",
          "Economic Policy Council",
          "South Gobi Development Council",
          "National Council for Vocational Education and Training",
        ],
      },
      {
        logo: require("@/assets/images/miningMongolia/activity/17052850612067096362.png"),
        list: [
          "Олборлох үйлдвэрлэлийн ил тод байдлын санаачилгын үндэсний зөвлөл",
          "Ашигт малтмалын тухай хуулийн шинэчилсэн найруулгын төсөл боловсруулах ажлын хэсэг",
          "Геологи уул уурхай, хүнд үйлдвэр, газрын тосны салбарын нийгэм, эдийн засгийн үнэлгээний ажлын хэсэг",
          "Mining week – 2023 чуулганыг зохион байгуулах ажлын хэсэг",
          "PDAC олон улсын арга хэмжээнд оролцох ажлын хэсэг",
        ],
        list_en: [
          "National Extractive Industries Transparency Initiative Council",
          "The working group for drafting the revision of the Law on Minerals",
          "Working groups for social and economic assessment of geology, mining, heavy industry and oil industry",
          "Working group to organize Mining Week 2023 conference",
          "Working group to participate in PDAC international event",
        ],
      },
      {
        logo: require("@/assets/images/miningMongolia/activity/1705285139296109180.png"),
        list: [
          "Хөдөлмөр нийгмийн түншлэлийн гурван талт үндэсний зөвлөл",
          "Хүнд машин механизмын мэргэжлийн зөвлөл",
          "Уртын ээлжийн ажлын хэсэг",
        ],
        list_en: [
          "Tripartite National Council of Labour Social Partnership",
          "Heavy Machinery Professional Council",
          "Working group of Long Shift",
        ],
      },
      {
        logo: require("@/assets/images/miningMongolia/activity/170528524926763084.png"),
        list: [
          "Эдийн засгийн бодлогын зөвлөл",
          "Ашигт малтмалын нөөц ашигласны төлбөрийн жишиг үнэ зарлах ажлын хэсэг",
        ],
        list_en: [
          "Working group to announce benchmark price of mineral royalties",
        ],
      },
      {
        logo: require("@/assets/images/miningMongolia/activity/1705285299982809601.png"),
        list: [
          "Стандарт хэмжил зүйн техникийн хороо",
          "Хүнд машин механизмын мэргэжлийн зөвлөл",
        ],
        list_en: ["Standard Metrology Techinal Committee"],
      },
    ]);

    const logos = ref([]);
    const isLoading = ref(false);

    const categorizeLogos = (logos) => {
      logos.forEach((image) => {
        if (image.public_relations_committee) {
          committees.value[0].logos.push({
            logo: image.logo,
            link: image.link,
          });
        }
        if (image.environment_committee) {
          committees.value[1].logos.push({
            logo: image.logo,
            link: image.link,
          });
        }
        if (image.legal_committee) {
          committees.value[2].logos.push({
            logo: image.logo,
            link: image.link,
          });
        }
        if (image.logistics_committee) {
          committees.value[3].logos.push({
            logo: image.logo,
            link: image.link,
          });
        }
      });
    };

    onMounted(async () => {
      try {
        isLoading.value = true;
        const res = await clientInstance.get("/get/logos");
        if (res.status === 200) {
          categorizeLogos(res.data.logos);
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    });

    return { lang, committees, mainWorkingGroups, isLoading, logos };
  },
};
</script>
