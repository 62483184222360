<template>
  <main
    class="flex justify-center bg-[#f7f7f7] min-h-[80vh] p-10 font-helvetica"
  >
    <div class="w-full min-[1200px]:w-5/6">
      <!-- breadcrumb nav -->
      <div
        v-motion-fade-visible-once
        :enter="{
          transition: {
            delay: 3000,
            duration: 3000,
          },
        }"
        class="flex gap-1 sm:gap-2 text-sm"
      >
        <router-link
          to="/yellowpage"
          :class="
            isRoute === '/yellowpage' ? 'text-[#CC4628]' : 'text-gray-500'
          "
          class="hover:underline"
          >{{ lang === "mn" ? "Нүүр хуудас" : "Home" }}</router-link
        >
        <span class="text-gray-500">/</span>
        <router-link
          :to="`/yellowpage/category/${mainRouteId}`"
          class="text-gray-500 hover:underline"
          >{{ mainRouteName }}</router-link
        >
        <span class="text-gray-500">/</span>
        <router-link
          :to="`/yellowpage/detail/${routeId}`"
          :class="
            isRoute === `/yellowpage/detail/${routeId}`
              ? 'text-[#CC4628]'
              : 'text-gray-500'
          "
          class="hover:underline"
          >{{ company.company_name }}</router-link
        >
      </div>

      <!-- title -->
      <h1
        class="border-l-4 border-[#09396C] text-[#09396C] text-2xl sm:text-3xl md:text-4xl uppercase font-bold pl-3 mt-8"
      >
        {{ company.company_name }}
      </h1>

      <!-- information -->
      <div class="flex max-sm:flex-col justify-between gap-10">
        <ul
          v-motion-fade-visible-once
          :enter="{
            transition: {
              delay: 3000,
              duration: 3000,
            },
          }"
          class="w-3/4 mt-5 space-y-2"
        >
          <li v-if="company.websites" class="flex items-center">
            <font-awesome-icon
              icon="fa-solid fa-globe"
              class="text-[#CC4628] mr-2"
            />
            <p
              v-for="(web, index) in company.websites"
              :key="index"
              class="mr-2"
            >
              {{ web }}
            </p>
          </li>

          <li v-if="company.emails" class="flex items-center">
            <font-awesome-icon
              icon="fa-solid fa-envelope"
              class="text-[#CC4628] mr-2"
            />
            <p
              v-for="(email, index) in company.emails"
              :key="index"
              class="mr-2"
            >
              {{ email }}
            </p>
          </li>

          <li v-if="company.phones" class="flex items-center">
            <font-awesome-icon
              icon="fa-solid fa-phone"
              class="text-[#CC4628] mr-2"
            />
            <p
              v-for="(phone, index) in company.phones"
              :key="index"
              class="mr-2"
            >
              {{ phone }}
            </p>
          </li>

          <li v-if="company.faxes" class="flex items-center">
            <font-awesome-icon
              icon="fa-solid fa-fax"
              class="text-[#CC4628] mr-2"
            />
            <p v-for="(fax, index) in company.faxes" :key="index" class="mr-2">
              {{ fax }}
            </p>
          </li>

          <li v-if="company.company_address" class="flex items-center">
            <font-awesome-icon
              icon="fa-solid fa-location-dot"
              class="text-[#CC4628] mr-2"
            />
            {{ company.company_address }}
          </li>
        </ul>

        <h1 class="w-1/4 uppercase text-[#CC4628] text-center text-6xl mb-3">
          ЛОГО
        </h1>
      </div>
    </div>
  </main>
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "YellowDetailPage",
  setup() {
    const route = useRoute();
    const isRoute = computed(() => route.path);
    const routeId = ref(route.params.id);
    const mainRouteId = ref("");

    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const companyId = ref(route.params.id);
    const company = ref({});

    const fetchCompany = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/company?lang=${lang.value}&id=${companyId.value}`
        );
        if (res.status === 200) {
          company.value = res.data.company;
          mainRouteId.value = res.data.company.category_code
            ? res.data.company.category_code.toString().substring(0, 2)
            : "";
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchCompany();
    });

    const categoryNames = {
      "01": { mn: "Уул уурхайн компани", en: "Mining Companies" },
      "02": { mn: "Ханган нийлүүлэгч", en: "Supplier Companies" },
      "03": { mn: "Үйлчилгээ үзүүлэгч", en: "Service Providers" },
      "04": { mn: "Бусад", en: "Others" },
    };

    const mainRouteName = computed(() => {
      return categoryNames[mainRouteId.value]?.[lang.value] || "Unknown";
    });

    watch(
      () => route.params.id,
      (newId) => {
        routeId.value = newId;
        isRoute.value = route.path;
      }
    );

    return {
      companyId,
      isLoading,
      company,
      lang,
      mainRouteId,
      mainRouteName,
      isRoute,
      routeId,
    };
  },
};
</script>
