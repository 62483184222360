import axios from "axios";

// const baseURL = "http://localhost:3000/client/yellow-page";

const baseURL = "https://miningmongolia.mn/client/yellow-page";

const clientYellowInstance = axios.create({
  baseURL,
  withCredentials: true,
});

export default clientYellowInstance;
